import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import TeamReducer from "./TeamReducer";
import MatchReducer from "./MatchReducer";
import UserReducer from "./UserReducer";
import DocumentReducer from "./DocumentReducer";
import {connectRouter} from 'connected-react-router'

import {
  SIGNOUT_USER_SUCCESS
} from "../../constants/ActionTypes";

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  team: TeamReducer,
  user: UserReducer,
  match: MatchReducer,
  document: DocumentReducer
});

export const rootReducer = history => (state, action) => {
  // console.log(state, action)

  if (action.type === SIGNOUT_USER_SUCCESS) {
    state = undefined
    // Only way to make sure no cache or personal information
    // is leaked to the next user after the log out
    // https://stackoverflow.com/a/50263511
    window.location.reload()

    // make cookies expire
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }

  return appReducer(history)(state, action);
}

