import { message } from "antd";
import {
    ACCESS_TOKEN_VALIDITY,
    USER_ACCESS_TOKEN_SET,
    USER_REFRESH_TOKEN_SET,
    VALIDATING_TOKEN,
    SIGNOUT_USER_SUCCESS,
    FETCH_ERROR,
    REFRESH_FAILED
} from "../../constants/ActionTypes";

import axios from "../../util/Api";
import { getUserProfile } from "../actions/UserActions";


const refreshAccessToken = (store, refreshToken) => {
    axios.post('api/token/refresh/', {
        refresh: refreshToken
    }).then(({data}) => {
        if (data) {
            localStorage.setItem("accessToken", JSON.stringify(data.access));
            localStorage.setItem("refreshToken", JSON.stringify(data.refresh));
            axios.defaults.headers.common['authorization'] = "Bearer " + data.access;

            // dispatch actions
            store.dispatch(getUserProfile()); // here I have a valid access token so it should be ok
            store.dispatch({type: USER_ACCESS_TOKEN_SET, payload: data.access}); // set the access token
            store.dispatch({type: USER_REFRESH_TOKEN_SET, payload: data.refresh});// set the refresh token
            store.dispatch({type: VALIDATING_TOKEN, payload: false})
        }
    }).catch((error) => {

        if (!store.getState().auth.refreshFailed) {
            store.dispatch({type: REFRESH_FAILED, payload: true});
            // Access token cannot be refreshed. Logout the user.
            message.info("Sessione scaduta. Verrai riportato al login.")

            // we must to this only once
            setTimeout(() => {
                store.dispatch({type: ACCESS_TOKEN_VALIDITY, payload: false})
                store.dispatch({type: VALIDATING_TOKEN, payload: false})
                localStorage.clear()
                store.dispatch({type: SIGNOUT_USER_SUCCESS})
            }, 3000)
        }
    })
}

const checkTokenValidity = (store, statusCode) => {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    if (accessToken) {
        // signal the start of the validation process
        store.dispatch({type: VALIDATING_TOKEN, payload: true})
        axios.post('api/token/verify/', {
            token: accessToken
        }).then((response) => {
            // needed when reloading or manually entering a URL
            store.dispatch({type: ACCESS_TOKEN_VALIDITY, payload: true})

            // update axios bearer token
            axios.defaults.headers.common['authorization'] = "Bearer " + accessToken;

            // if (store.getState().user.profile === null && (![401, 403].includes(statusCode))) {
            //     console.log("Getting profile")
            //     // console.log("current profile: ", store.getState().user.profile)
            //     store.dispatch(getUserProfile());
            // }

            // signal the end of the validation process
            store.dispatch({type: VALIDATING_TOKEN, payload: false})
        }).catch((error) => {
            // Here we should try refreshing the token
            const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
            if (refreshToken)
                refreshAccessToken(store, refreshToken)
        })
    }
}

/**
 * This middleware checks the access token's validity each time
 * a new location within the application is requested or a
 * fetch request has failed. Moreover,
 * it dispatches appropriate actions to change its validity.
 */
export const checkTokenExpirationMiddleware = store => next => action => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            checkTokenValidity(store)
            break;
        }
        case FETCH_ERROR: {
            const error = action.payload;
            console.log(error.response)
            // if request failed due to unauthorized status, verify token's validity
            if(error.response && [403, 401].includes(error.response.status))
                checkTokenValidity(store, error.response.status)

            break;
        }

        default:
            break;
    }

    return next(action);
}