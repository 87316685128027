import { 
    GOT_TEAMS, // signals the moment we get the list of teams
    RETRIEVING_TEAMS, // sigals the start and end of the process
    CURRENT_TEAM_SET, // signals when the current team has been changed
    CURRENT_TEAM,
    GOT_TEAMS_LIST, // the current team
} from "../../constants/ActionTypes";

const INIT_STATE = {
    retrievingTeams: false,
    teams: JSON.parse(localStorage.getItem('teams')),
    teamsList: JSON.parse(localStorage.getItem('teamsList')), // sorted by name
    currentTeam: JSON.parse(localStorage.getItem('currentTeam')),
    currentTeamSet: false
}

const TeamReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        
        // set the teams' object
        case GOT_TEAMS: {
            // return the new state
            return {...state, teams: action.payload}
        }

        case GOT_TEAMS_LIST: {
            return {...state, teamsList: action.payload}
        }

        case RETRIEVING_TEAMS: {
            return {...state, retrievingTeams: action.payload}
        }

        case CURRENT_TEAM: {
            return {
                ...state,
                currentTeam: action.payload
            }
        }

        case CURRENT_TEAM_SET: {
            return {
                ...state,
                currentTeamSet: action.payload
            }
        }

        default:
            return state;
    }
}

export default TeamReducer;