import {
    FETCH_ERROR,
    GOT_DOCUMENTS,
    SET_DOCUMENTS_STATUS,
    GOT_FOLDERS,
    SET_FOLDERS_STATUS,
    ADD_DOCUMENT,
    DELETE_DOCUMENT,
    CHANGE_DOCUMENT,
    SET_SORT_FASHION,
} from "../../constants/ActionTypes"
import axios from "../../util/Api"
import { initLoginFailed, MAX_RETRIES } from "../../util/utils";

const retry = require('retry');

/**
 * Retrieves all the documents that belong to the specified season and folder;
 * the documents are grouped by month.
 * @param {String} season the season the documents we are retrieving belong to
 * @param {Integer} folder the folder unique identifier
 */
export const getDocumentsBySeasonAndFolder = (season, folder, broadcaster) => {
    const params = []
    if (season) {
        params.push(`season=${season}`)
    }
    if (folder) {
        params.push(`folder=${folder}`)
    }
    if (broadcaster) {
        params.push(`broadcaster=${broadcaster}`)
    }
    const url = 'api/get_documents_by_season/?' + params.join('&')

    return (dispatch) => {
        dispatch({ type: SET_DOCUMENTS_STATUS, payload: 'loading' })
        axios.get(url).then((response) => {
            if (response.data) {
                dispatch({type: GOT_DOCUMENTS, payload: response.data})
            }
            dispatch({ type: SET_DOCUMENTS_STATUS, payload: 'complete' })
        }).catch((error) => {
            dispatch({ type: FETCH_ERROR, payload: error })
            dispatch({ type: SET_DOCUMENTS_STATUS, payload: 'failed' })
        })
    }
}

/**
 * Get all the folders
 */
// export const getFolders = () => {
//     return (dispatch) => {
//         dispatch({ type: SET_FOLDERS_STATUS, payload: 'loading' })
//         axios.get('api/folders/').then((response) => {
//             if (response.data) {
//                 localStorage.setItem('folders', JSON.stringify(response.data))
//                 dispatch({type: GOT_FOLDERS, payload: response.data})
//             }
//             dispatch({ type: SET_FOLDERS_STATUS, payload: 'complete' })
//         }).catch((error) => {
//             dispatch({ type: FETCH_ERROR, payload: error })
//             dispatch({ type: SET_FOLDERS_STATUS, payload: 'failed' })
//         })
//     }
// }

export const getFolders = () => {
    return (dispatch) => {
        dispatch({ type: SET_FOLDERS_STATUS, payload: 'loading' })
        const op = retry.operation({
            retries: MAX_RETRIES,
            factor: 3,
            minTimeout: 1 * 1000,
            maxTimeout: 60 * 1000,
            randomize: true,
        });
        op.attempt(async (currAttempt) => {
            console.log('sending request: ', currAttempt, ' attempt');
            let res;
            try {
                res = await axios.get('api/folders/');
            } catch (e) {
                dispatch({ type: FETCH_ERROR, payload: e }); // check error status code for unauthorized users
                if (op.retry(e)) { return; } // retry!
            }

            // ok here we are good
            // if either res is null or undefined or res.data doesn't contain any data, reset
            if (res && res.data) {
                localStorage.setItem('folders', JSON.stringify(res.data))
                dispatch({type: GOT_FOLDERS, payload: res.data})
                dispatch({ type: SET_FOLDERS_STATUS, payload: 'complete' })
            } else {
                dispatch({ type: SET_FOLDERS_STATUS, payload: 'failed' })
                initLoginFailed(dispatch);
            }
        })

        // retry(async () => {
        //     console.log("retrying")
        //     let res;

        //     res = await axios.get('api/folders/');
        //     console.log(res)

        //     if (res.data) {
        //         localStorage.setItem('folders', JSON.stringify(res.data))
        //         dispatch({type: GOT_FOLDERS, payload: res.data})
        //     }

        //     dispatch({ type: SET_FOLDERS_STATUS, payload: 'complete' })

        // }, {
        //     retries: 3
        // })

        // axios.get('api/folders/').then((response) => {
        //     if (response.data) {
        //         localStorage.setItem('folders', JSON.stringify(response.data))
        //         dispatch({type: GOT_FOLDERS, payload: response.data})
        //     }
        //     dispatch({ type: SET_FOLDERS_STATUS, payload: 'complete' })
        // }).catch((error) => {
        //     dispatch({ type: FETCH_ERROR, payload: error })
        //     dispatch({ type: SET_FOLDERS_STATUS, payload: 'failed' })
        // })
    }
}

/**
 * Add the specified document to the document list.
 * The document will be added to the month group derived
 * by its date.
 * E.g document's date is 13/11/2020, month group will be November
 * @param {Object} document the document to be added
 */
export const addDocument = (month, document) => {
    return (dispatch) => {
        dispatch({type: ADD_DOCUMENT, payload: {month: month, doc: document}})
    }
}

/**
 * Delete the document associated to the specified id.
 * @param {Integer} documentId the id of the document to be deleted
 * @param {String} monthGroup the month group the document belongs to
 */
export const deleteDocument = (documentId, monthGroup) => {
    return (dispatch) => {
        dispatch({type: DELETE_DOCUMENT, payload: {id: documentId, month: monthGroup}})
    }
}

/**
 * Replace the document associated to the specified id
 * with the specified new document.
 * @param {Integer} documentId the id of the document to be modified
 * @param {String} monthGroup the month group the document belongs to
 * @param {Object} newDocument the new document that will replace the old document
 */
export const modifyDocument = (documentId, monthGroup, newDocument) => {
    return (dispatch) => {
        dispatch({type: CHANGE_DOCUMENT, payload: {id: documentId, month: monthGroup,  doc: newDocument}})
    }
}

/**
 * Set the specified sort fashion for documents.
 * @param {String} sortFashion the sort modality (i.e ascending | descending)
 */
export const setDocumentsSortFashion = (sortFashion) => {
    return (dispatch) => {
        if (sortFashion === 'ascending' || sortFashion === 'descending')
            dispatch({type: SET_SORT_FASHION, payload: sortFashion})
    }
}