import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { Popover } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

import {userSignOut} from "appRedux/actions/Auth";

const UserInfo = () => {

  const dispatch = useDispatch();
  const {profile} = useSelector((state) => state.user);
  const userMenuOptions = (
    <ul className="gx-user-popover">
      {
        ( profile.is_lega || 
          (profile.permissions && profile.permissions.includes("view_document_abbonati_sky")) || 
          (profile.permissions && profile.permissions.includes("view_document_economico_finanziari")) 
        ) && 
        <li>
          <a href="/user-profile" style={{color: '#333333'}}>Profilo</a>
        </li>
      }
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <div className="user-profile">
        <div className="user-email">
          {profile ? profile.email : ''}
        </div>
        <div className="user-icon">
          <FontAwesomeIcon icon={faUserCircle} />
        </div>
      </div>
    </Popover>
  )
};

export default UserInfo;
