import React, {memo, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {ConfigProvider} from "antd";
import {IntlProvider} from "react-intl";


import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import ResetPassword from "../ResetPassword";
import {setInitUrl} from "appRedux/actions/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";
import CircularProgress from "../../components/CircularProgress/index";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const App = () => {
  const dispatch = useDispatch();
  const {locale, navStyle, layoutType} = useSelector(({settings}) => settings);
  const {accessToken, tokenValidity, validatingToken, initURL} = useSelector(({auth}) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();


  // useEffect(() => {
  //   if (accessToken)
  //     dispatch(changeTokenValidity(accessToken))
  // }, [dispatch, accessToken]) // initially it was empty

  /**
   * This hook has no dependency array. When useEffect hook has no dependency
   * array it gets execute on each render (actually AFTER the render).
   * useEffect hook, as well as the other hooks, are called after the component is mounted.
   * This means that all the initial instructions of the component (function)
   * are executed, then the render is executed and then useEffect kicks in because
   * it follows a bottom-up execution (i.e the effects resolve first in the children
   * and then in the parent.)
   *
   * As a side note: the elements in the DOM become accessible after the components
   * are rendered but BEFORE the effects are resolved.
   *
   * When useEffect has a dependency array, the effect will be triggered when
   * the component mounts (1. everything outside the render, 2. everything inside
   * the render, 3. useEffect) and when any of the dependency changes.
   */
  useEffect(() => {
    // console.log("App's first useEffect");
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);
  }, []);

  /**
   * This hook is resolved when location.pathname changes (i.e URL changes)
   * The first time the App component is rendered, initURL is empty string ('')
   * so we enter the first if statement and initURL is set to the current
   * location.pathname. If our URL is something:3000 without the leading /
   * the initURL is set to / because location.pathname is /.
   * Same thing if our URL is something:3000/ with the leading /.
   * If URL is something:3000/signin, location.pathname is equal to /signin
   * so initURL will be set to /signin.
   */
  useEffect(() => {
    if (initURL === '') {
      // set initUrl to the current pathname (depends on what we write as the URL)
      dispatch(setInitUrl(location.pathname));
    }
    // instead of pushing to /reset, push to / and follow the normal flow.
    else if (initURL === '/reset/') {
      dispatch(setInitUrl('/'));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [dispatch, initURL, layoutType, location.pathname, location.search, navStyle]);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  /**
   * This effect is resolved after the second useEffect in which initURL is set
   * to the current pathname. If in the second useEffect pathname is /, then
   * initURL is set to / and when the third useEffect is resolved the if statement
   * where we check for pathname === / will be true.
   *
   * If we haven't logged in yet, token is null so we will be pushed to /signin
   * otherwise token is not null and if initURL has been set to one of '', '/'
   * '/signin', the user will be taken to the landing page (home)
   *
   * if we haven't logged yet and we try to land on a page whose access is restricted
   * we will be redirected to the signin page.
   * Nonetheless, initURL has been set to that page (i.e /ticketing) and after
   * signin it's been done, we will be taken to that exact page.
   */
  useEffect(() => {
    // console.log(location.pathname, match.url)
    if (location.pathname === '/') {

      if (accessToken === null && !tokenValidity) {
        history.push('/signin');
      }
      // if (accessToken === null) {
      //   history.push('/signin');

      // if the user is authorized and comes here after the login page (where pathname is set to /)
      // and tries to enter one of these pages, it gets redirected to the home page.
      else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/reset/' || initURL === '/reset') {
        // token is not null and valid
        history.push('/home');
      }
      else {
        // alert(initURL)
        // history.push(initURL);
        // problem: initURL is /reset
        // if we push to initURL we will go to /reset instead of home after login
        // if we push home is ok
        // if we push to signin infinite loop because signin pushes us to / and we end up here.
        // history.push('/')
        history.push(initURL !== '/home' ? '/home' : initURL)
      }
    }
  }, [accessToken, initURL, location, history, tokenValidity]); // tokenValidity has been added in a second moment

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>

        <Switch>

          <Route exact path='/signin' component={SignIn}/>
          <Route exact path='/reset' component={ResetPassword}/>

          {/* The access to the MainApp component is restricted. This means that
              access is allowed only to authorized users.
              We show a loader while we check for the token validity.
              Then, based on its validity value we send the user to the signin page
              or to the MainApp component.
          */}
          {validatingToken
            ? <CircularProgress className="gx-loader-400"/>
            : tokenValidity === true
              ? <MainApp path={`${match.url}`} />
              : <Redirect to={{
                pathname: '/signin',
                state: {from: location}
              }} />

          }
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
};

export default memo(App);