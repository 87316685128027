import {
    FETCH_START,
    USER_PROFILE,
    GOT_PROFILE,
    FETCH_SUCCESS,
    FETCH_ERROR,
    GOT_FILTERS,
    FILTERS_CHANGED,
    SET_FILTERS,
    UPDATE_FILTER_CURR_VALUES,
    SET_FORM_VALUES,
    GOT_BROADCASTERS,
    SET_BROADCASTERS,
    UPDATE_FIRST_CURRENT_ROUND,
    SET_EXCLUDED_COUNTRIES_STATUS,
    SET_EXCLUDED_COUNTRIES,
    SET_AGGREGATED_COUNTRIES,
    SET_AGGREGATED_COUNTRIES_STATUS,
    SET_LAST_PLAYED_ROUND,
    GOT_FILTERS_SEASON_CHANGE,
    SET_USERS_STATUS,
    GOT_USERS,
    SET_USERS_SORT_FASHION,
    SET_GROUPS_STATUS,
    GOT_GROUPS,
    ADD_USER,
    DELETE_USER,
    CHANGE_USER,
} from "../../constants/ActionTypes";

import {
  listToObject,
  addCurrValues,
  getCurrValues,
  getDefaultFilterValue,
  containsObject,
  MAX_RETRIES,
  initLoginFailed
} from "../../util/utils";

import axios from 'util/Api'
import { getLastRound, getMostRecentSeason } from "./MatchActions";
import moment from 'moment';
moment().format();

const retry = require('retry');

/**
 * Gets the user profile and sets it in both the localstorage
 * and the redux's state
 */
export const getUserProfile = () => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      const op = retry.operation({
        retries: MAX_RETRIES,
        factor: 3,
        minTimeout: 1 * 1000,
        maxTimeout: 60 * 1000,
        randomize: true,
      });

      op.attempt(async (currAttempt) => {
        // console.log("Profile: attempt number ", currAttempt)
        if (currAttempt <= MAX_RETRIES) {
          axios.get('api/profile/').then(({data}) => {
            if (data) {
              // save user's profile on local storage
              localStorage.setItem("profile", JSON.stringify(data));
              dispatch({type: USER_PROFILE, payload: data});
              dispatch({type: GOT_PROFILE, payload: true});
              dispatch({type: FETCH_SUCCESS});
            } else {
              console.log("User profile is null. Aborting.")
              initLoginFailed(dispatch);
              // dispatch({type: FETCH_ERROR, payload: "Couldn't retrieve user's profile."});
            }
          }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error});
            console.log("Error****:", error.message);
            if (op.retry(error)) { return; }
          })
        } else {
          initLoginFailed(dispatch);
        }
      })
    }
  };




/**
 * This action dispatcher fetches both the default values for
 * the form's filters and all the values in the database for each form element.
 * E.g: all the seasons, competitions, areas, broadcasters etc.
 * @param {String} season The currently selected season (optional)
 * @param {String} competition the currently selected competition (default is 'Serie A')
 * @param {Boolean} isLogin indicates whether we are fetching filters at login stage
 */
export const getFilters = (season, competition, isLogin) => {
    return async (dispatch, getState) => {
        dispatch({type: FETCH_START});

        let mostRecentSeason;
        if (season === undefined) {
          mostRecentSeason = await dispatch(getMostRecentSeason());

          if (mostRecentSeason) {
            mostRecentSeason = mostRecentSeason.name;
          } else {
            const currYear = moment().year();
            mostRecentSeason = `${currYear.toString()}/${(currYear-1).toString()}`;
          }
        } else {
          mostRecentSeason = season
        }

        let currCompetition;
        if (competition === undefined) {
          currCompetition = 'Serie A';
        } else {
          currCompetition = competition;
        }

        const op = retry.operation({
          retries: MAX_RETRIES,
          factor: 3,
          minTimeout: 1 * 1000,
          maxTimeout: 60 * 1000,
          randomize: true,
        });

        op.attempt(async (currAttempt) => {
          // console.log("Filters: attempt number ", currAttempt)
          if (currAttempt <= MAX_RETRIES) {
            axios.get(`api/filters/?season=${mostRecentSeason}&competition=${currCompetition}`).then(async (response) => {

                if (response.data) {
                    // convert list of filter objects to object of filter objects
                    // using the filter's name as key
                    // true means add a current value field to each object
                    let filtersObj = listToObject(response.data, "name", false, false)

                    // retrieve the most recently played round
                    let lastRound = await dispatch(getLastRound(filtersObj.season.values[0].name, currCompetition))

                    if (isLogin) {
                      // add default values to filters
                      addCurrValues(filtersObj); // we're not passing second parameter because we don't want to set last round
                      // default will be whole seasoninn
                    } else {
                      /**
                       * In this case we must check that old filters' current values
                       * can be set as current values for the new combination of
                       * season and competition
                       */
                      const oldCurrFiltersValues = getCurrValues(getState().user.filters);
                      for (const [filter, oldValue] of Object.entries(oldCurrFiltersValues)) {
                        switch(filter) {
                          case "season":
                          case "location":
                          case "competition":
                            // get new filter's values
                            const newValues = filtersObj[filter].values;

                            // verify that old current value is present in new values
                            const isValid = containsObject(newValues, oldValue);
                            filtersObj[filter].currValue = isValid ? oldValue : getDefaultFilterValue(filter)

                            break;
                          case "country":
                            const newCountries = filtersObj['country'].values;
                            /**
                             * If filter is country, the following can happen:
                             * we can select a certain country on a season, let's say
                             * Argentina on 2018/2019. That same country could
                             * potentially be part of an aggregated market on another
                             * seasons (e.g 2017/2018). What should we do in this case ?
                             */
                            const isOldCountryValid = containsObject(newCountries, oldValue);

                            /**
                             * If the user hasn't selected a country yet, the country's currValue
                             * object will look like this: {name: "", label: "Seleziona una nazione"}
                             * We must preserve that value as the user hasn't made a selection yet.
                             */
                            const noSelectionCountry = {name: "", label: "Seleziona una nazione"};

                            if (JSON.stringify(oldValue) === JSON.stringify(noSelectionCountry)) {
                              filtersObj['country'].currValue = noSelectionCountry;
                            } else {
                              filtersObj['country'].currValue = isOldCountryValid ? oldValue : getDefaultFilterValue('country')
                            }

                            break;
                          case "round":
                            // fow now leave the same round selection
                            filtersObj["round"].currValue = oldValue;

                            break;
                          case "broadcaster":
                            /**
                             * Verify that the old country's value is present in the keys
                             * of the new broadcaster object and if true, that the old broadcaster
                             * as a whole is present among the new broadcasters
                             * associated to the old country.
                             * E.g
                             * 1. season = 2017/2018, country = Italia, Broadcaster = Mediaset Premium
                             * 2. season = 2018/2019, country = Italia
                             *
                             * broadcaster Mediaset Premium isn't available in 2018/2019 season, so
                             * the first guard is evaluted true (i.e IT is included in the new broadcaster's keys)
                             * but second guard is evaluted false because the old broadcaster object
                             * {name: "Mediaset Premium", label: "Mediaset Premium"} isn't included in
                             * the current broadcaster list associated to country IT (Italy)
                             */
                            // new current values for broadcaster filter
                            const broadcasters = filtersObj.broadcaster.values;

                            if (Object.keys(broadcasters).includes(oldCurrFiltersValues.country.name) &&
                                containsObject(broadcasters[oldCurrFiltersValues.country.name], oldValue)) {
                                filtersObj['broadcaster'].currValue = oldValue;
                            } else {
                              const countryName = filtersObj['country'].currValue.name;
                              if (filtersObj['broadcaster'].values[countryName] !== undefined) {
                                filtersObj['broadcaster'].currValue = filtersObj['broadcaster'].values[countryName][0];
                              } else {
                                // set current country to IT perhaps ??
                                filtersObj['broadcaster'].currValue = getDefaultFilterValue('broadcaster');
                              }
                            }

                            break;
                          default:
                            console.log("This filter doesn't exist.")
                        }
                      }

                      console.log("after switch: ", getCurrValues(filtersObj))
                    }

                    // save last played round
                    dispatch({type: SET_LAST_PLAYED_ROUND, payload: lastRound})
                    localStorage.setItem("lastRound", JSON.stringify(lastRound));


                    localStorage.setItem("filters", JSON.stringify(filtersObj));
                    dispatch({type: SET_FILTERS, payload: filtersObj})

                    if (isLogin) {
                      dispatch({type: GOT_FILTERS, payload: true})
                    } else {
                      dispatch({type: GOT_FILTERS_SEASON_CHANGE, payload: true})
                    }

                    dispatch({type: FETCH_SUCCESS});
                } else {
                    // dispatch({type: FETCH_ERROR, payload: "No filters available."});
                    initLoginFailed(dispatch);
                }
            }).catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error});
                console.log("Error while retrieving filters.:", error.message);
                if (op.retry(error)) { return; }
            })
          } else {
            initLoginFailed(dispatch);
          }
        })
    }
}

/**
 * As we are initializing the reducer's state by looking for
 * the values of filters on the local storage, each time
 * the page is reloaded the state is lost and redux state is
 * recreated starting from the INIT_STATE defined in the reducer.
 * By persisting in into the local storage we are assuring that
 * the values of filters are stored and available after a page refresh.
 *
 * @param {Object} additional An object containing filter name and current values
 * for that filter. If specified, replace the old values with these.
 */
export const persistFilters = (additional) => {
  return (dispatch, getState) => {
    let currFilters = getState().user.filters;
    if (additional !== undefined) {
      currFilters[additional.filterName] = additional.filterValues;
    }

    localStorage.setItem('filters', JSON.stringify(currFilters))
  }
}

/**
 * Same as filters. NOT USED
 */
export const persistFormValues = () => {
    return (dispatch, getState) => {
      localStorage.setItem('formValues', JSON.stringify(getState().user.formValues))
    }
}

/**
 * Updates the filters current values
 * newCurrValues is an object with filters' names as keys
 * e.g
 * {"filterName": name, "newValue": {"name": name, "label": label}}
 *
 * @param {Object} newCurrValue the new current value WITH THE NAME OF THE FILTER;
 * a new round to be added/deleted to/from the current rounds in case the filter is round
 * @param {Object} options an options object where it is possible to p
 * specify whether the filter that must be updated is round an which type
 * of update we need to do (add or delete)
 */
export const updateFilterCurrValue = (newCurrValue, updateType) => {
  return (dispatch, getState) => {
    dispatch({type: FILTERS_CHANGED, payload: true})
    // console.log(newCurrValue, updateType)

    // if (updateType !== undefined && newCurrValue.filterName === 'round') {
    //   if (updateType === 'ADD') {
    //     // console.log("qua =")
    //     dispatch({type: ADD_ROUND_TO_CURRENT_ROUNDS, payload: newCurrValue})
    //   } else if (updateType === 'DELETE') {
    //     dispatch({type: DELETE_ROUND_FROM_CURRENT_ROUNDS, payload: newCurrValue})
    //   } else if (updateType === 'UPDATE_FIRST') {
    //     dispatch({type: UPDATE_FIRST_CURRENT_ROUND, payload: newCurrValue})
    //   }

    // } else {
    //   dispatch({type: UPDATE_FILTER_CURR_VALUES, payload: newCurrValue})
    // }
    if (updateType !== undefined && newCurrValue.filterName === 'round') {
      if (updateType === 'UPDATE_FIRST') {
        dispatch({type: UPDATE_FIRST_CURRENT_ROUND, payload: newCurrValue})
      }
    } else {
      dispatch({type: UPDATE_FILTER_CURR_VALUES, payload: newCurrValue})
    }

    // persist filters each time the current values of a filter changes
    // but here we don't have the updated filters object.
    // find out if getState().user.filters updates as soon as the previous
    // dispatch is executed (I don't think so)
    // console.log("New values of filters: ", getState().user.filters)
    dispatch(persistFilters())
  }
}

/**
 * Updates the form's values on the redux state and in the LS.
 * @param {Object} formValues the form values
 */
export const updateFormValues = (formValues) => {
  return (dispatch, getState) => {
    dispatch({type: SET_FORM_VALUES, payload: formValues})
    localStorage.setItem('formValues', JSON.stringify(getState().user.formValues))
  }
}


/**
 * Fetches the broadcaster list from the database.
 */
export const getBroadcasters = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START})
    const op = retry.operation({
      retries: MAX_RETRIES,
      factor: 3,
      minTimeout: 1 * 1000,
      maxTimeout: 60 * 1000,
      randomize: true,
    });

    op.attempt(async (currAttempt) => {
      // console.log('Broadcasters: attempt number ', currAttempt);
      if (currAttempt <= MAX_RETRIES) {
        axios.get('api/broadcasters/').then((response) => {

          if(response.data) {
            const broadcasters = listToObject(response.data, 'id', false, false);

            localStorage.setItem('broadcasters', JSON.stringify(broadcasters));
            dispatch({type: GOT_BROADCASTERS, payload: true});
            dispatch({type: SET_BROADCASTERS, payload: broadcasters});
          } else {
            initLoginFailed(dispatch);
          }

        }).catch((error) => {
          dispatch({type: GOT_BROADCASTERS, payload: false});
          dispatch({type: FETCH_ERROR, payload: error})
          console.log("Error while fetching broadcasters.")
          if (op.retry(error)) { return; }
        })
      } else {
        initLoginFailed(dispatch);
      }
    })

  }
}


// MOVE IT
/**
 * Fetches the list of codes of all the countries to exclude from
 * the map selection (i.e each country which isn't associated to
 * a market) for the specified season and competition.
 * @param {String} season The currently selected season
 * @param {String} competition the currently selected competition
 */
export const getCountriesToExclude = (season, competition) => {
  return (dispatch, getState) => {
    dispatch({type: SET_EXCLUDED_COUNTRIES_STATUS, payload: 'loading'})

    // retrieve currently selected season
    // const season = getState().user.filters.season.currValue;

    axios.get(`api/get_inactive_countries_codes/?season=${season}&competition=${competition}`).then((response) => {
      // could be empty
      dispatch({type: SET_EXCLUDED_COUNTRIES, payload: response.data})
      dispatch({type: SET_EXCLUDED_COUNTRIES_STATUS, payload: 'complete'})
    }).catch((error) => {
      dispatch({type: FETCH_ERROR, payload: error})
      dispatch({type: SET_EXCLUDED_COUNTRIES_STATUS, payload: 'failed'})
    })
  }
}

// MOVE IT

/**
 * Retrieves the list of aggregated countries (Pan America Latina etc..)
 * for the specified season and competition.
 * @param {String} season The currently selected season
 * @param {String} competition the currently selected competition
 */
export const getAggregatedCountries = (season, competition) => {
  return (dispatch) => {
    dispatch({type: SET_AGGREGATED_COUNTRIES_STATUS, payload: 'loading'})
    axios.get(`api/get_aggregated_markets/?season=${season}&competition=${competition}`).then((response) => {
      // could be empty
      dispatch({type: SET_AGGREGATED_COUNTRIES, payload: response.data})
      localStorage.setItem('aggregatedCountries', JSON.stringify(response.data))
      dispatch({type: SET_AGGREGATED_COUNTRIES_STATUS, payload: 'complete'})
    }).catch((error) => {
      dispatch({type: FETCH_ERROR, payload: error})
      dispatch({type: SET_AGGREGATED_COUNTRIES_STATUS, payload: 'failed'})
    })
  }
}

/**
 * Retrieves all the user accounts the requester can see.
 * Results are grouped by user type (lega | club)
 */
export const getUsersByType = () => {
  return (dispatch) => {

      dispatch({ type: SET_USERS_STATUS, payload: 'loading' })
      axios.get('api/get_users_grouped_by_type/').then((response) => {
          if (response.data) {
              dispatch({type: GOT_USERS, payload: response.data})
          }
          dispatch({ type: SET_USERS_STATUS, payload: 'complete' })
      }).catch((error) => {
          dispatch({ type: FETCH_ERROR, payload: error })
          dispatch({ type: SET_USERS_STATUS, payload: 'failed' })
      })
  }
}


/**
 * Add the specified user to the user list.
 * The user will be added to the specified user group.
 * E.g userGroup can be either lega or club
 * @param {Object} user the user to add
 */
export const addUser = (userGroup, user) => {
  return (dispatch) => {
      dispatch({type: ADD_USER, payload: {userGroup: userGroup, user: user}})
  }
}

/**
* Delete the user associated to the specified id.
* @param {Integer} userId the id of the user to be deleted
* @param {String} userGroup the user group the user belongs to
*/
export const deleteUser = (userId, userGroup) => {
  return (dispatch) => {
      dispatch({type: DELETE_USER, payload: {id: userId, userGroup: userGroup}})
  }
}

/**
* Replace the user associated to the specified id
* with the specified new user.
* @param {Integer} userId the id of the user to be modified
* @param {String} monthGroup the month group the user belongs to
* @param {Object} newUser the new user that will replace the old user
*/
export const modifyUser = (userId, userGroup, newUser) => {
  return (dispatch) => {
      dispatch({type: CHANGE_USER, payload: {id: userId, userGroup: userGroup,  user: newUser}})
  }
}


/**
 * Set the specified sort fashion for users.
 * @param {String} sortFashion the sort modality (i.e ascending | descending)
 */
export const setUsersSortFashion = (sortFashion) => {
  return (dispatch) => {
      if (sortFashion === 'ascending' || sortFashion === 'descending')
          dispatch({type: SET_USERS_SORT_FASHION, payload: sortFashion})
  }
}

/**
 * Get all user groups
 */
export const getGroups = () => {
  return (dispatch) => {
      dispatch({ type: SET_GROUPS_STATUS, payload: 'loading' })
      axios.get('api/get_groups/').then((response) => {
          if (response.data) {
              localStorage.setItem('userGroups', JSON.stringify(response.data))
              dispatch({type: GOT_GROUPS, payload: response.data})
          }
          dispatch({ type: SET_GROUPS_STATUS, payload: 'complete' })
      }).catch((error) => {
          dispatch({ type: FETCH_ERROR, payload: error })
          dispatch({ type: SET_GROUPS_STATUS, payload: 'failed' })
      })
  }
}
