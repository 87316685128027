import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover, Select} from "antd";
import {setCurrentTeam} from "../../appRedux/actions/TeamActions";
import {loadImage} from "../../util/utils";
import { Loader } from "../../components/CircularProgress/Loader";

const {Option} = Select;


const TeamSelection = () => {
  const dispatch = useDispatch();
  const {profile, gotProfile} = useSelector((state) => state.user);
  const {teams, retrievingTeams, currentTeam} = useSelector((state) => state.team);

  /**
   * If both profile and the list of teams have been fetched,
   * the team to display depends on the user's role.
   * If it's admin we set as the current team the first in the list.
   * Otherwise we display the redux's currentTeam.
   * If user is not admin, we set as the current team the team that we get along
   * its profile.
   */
  // useEffect(() => {
  //   let team;
  //   /**
  //    * After log in, when switching from Home to Audience page,
  //    * gotProfile is true and retrievingTeams is false. Because
  //    * of that, when we land on Audience Page, this useEffect will
  //    * kick in, updating the current team by setting it to the first
  //    * in the user profile's list.
  //    * Update it only if currentTeam is null.
  //    */
  //   // if (gotProfile && !retrievingTeams) {
  //   if ((profile !== null || gotProfile) && !retrievingTeams) {
  //   // if (gotProfile && teams !== null) {
  //     // set current only if hasn't been set yet
  //     if (!currentTeam) {
  //       team = profile.teams[0]
  //       dispatch(setCurrentTeam(team))
  //     }
  //   }
  // }, [gotProfile, retrievingTeams])

  useEffect(() => {
    if (profile && teams && !currentTeam) {
      console.log("Only once")
      const team = profile.teams[0];
      dispatch(setCurrentTeam(team));
    }
  }, [profile, teams, currentTeam, dispatch])

  /**
   * Sets the redux's current team when a new team
   * is selected from the select menu.
   * @param {Integer} teamId The team id
   */
  const handleTeamSelection = (teamId) => {
    if (teams !== null)
      dispatch(setCurrentTeam(teams[teamId]));
  }

  /**
   * Returns a list of Options elements, one for each team.
   */
  const teamsList = () =>
    retrievingTeams && (profile !== null || gotProfile)
      ? <Loader />
      : (
        // use profile.teams and not team's teams
        // Object.values(teams).map((team, idx) => (
          profile !== null
          ? (
              profile.teams.map((team, idx) => (
                <Option value={team.id} key={team.id}>
                  <Avatar
                    src={loadImage("teams", team.name === 'Juventus' ? 'JuventusBlack.png' : team.logo)}
                    key={team.id}
                    className="gx-size-40 gx-mr-3 scale-down" alt=""
                  />
                  {team.name}
                </Option>
            ))
          )
          : []
      )

  /**
   * A micro component which displays a select
   * with search functionality. It allows to search
   * for a team based on its name.
   */
  const TeamsSelect = () => (
    <Select
      showSearch
      style={{ width: 170 }}
      placeholder="Scegli la squadra"
      optionFilterProp="children"
      onChange={handleTeamSelection}
      value={currentTeam ? currentTeam.name : ''}
    >
      { teamsList() }
    </Select>
  )


  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-ml-0 gx-avatar-row">
      {
        profile && (profile.role === 'admin' || profile.is_lega)
        ?(
          <Popover
            placement="bottomLeft"
            content={!retrievingTeams && <TeamsSelect />} // wait until  teams are fetched
            trigger="click">
            <Avatar src={
              currentTeam
                ? loadImage("teams", currentTeam.logo)
                : <Loader />
              }
              className="gx-size-60 gx-pointer gx-mr-2 scale-down" alt=""/>
            <span className="gx-avatar-name team-name">{currentTeam ? currentTeam.name : ''}<i
              className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
          </Popover>
        ) : (
          <>
          <Avatar src={
            currentTeam
              ? loadImage("teams", currentTeam.logo)
              : <Loader />
            }
            className="gx-size-60 gx-pointer gx-ml-3 gx-mr-2" alt=""
          />
          <span className="gx-avatar-name team-name">{currentTeam ? currentTeam.name : ''}</span>
          </>
        )
      }
    </div>
  )
};

export default TeamSelection;
