import {
    GOT_PROFILE,
    USER_PROFILE,
    GOT_FILTERS,
    SET_FILTERS,
    UPDATE_FILTER_CURR_VALUES,
    FILTERS_CHANGED,
    SET_FORM_VALUES,
    GOT_BROADCASTERS,
    SET_BROADCASTERS,
    DELETE_ROUND_FROM_CURRENT_ROUNDS,
    ADD_ROUND_TO_CURRENT_ROUNDS,
    UPDATE_FIRST_CURRENT_ROUND,
    SET_EXCLUDED_COUNTRIES,
    SET_EXCLUDED_COUNTRIES_STATUS,
    SET_AGGREGATED_COUNTRIES,
    SET_AGGREGATED_COUNTRIES_STATUS,
    GOT_FILTERS_SEASON_CHANGE,
    GOT_USERS,
    ADD_USER,
    DELETE_USER,
    CHANGE_USER,
    SET_USERS_STATUS,
    SET_USERS_SORT_FASHION,
    GOT_GROUPS,
    SET_GROUPS_STATUS
} from "../../constants/ActionTypes";

const INIT_STATE = {
    profile: JSON.parse(localStorage.getItem('profile')),
    gotProfile: false,
    filters: JSON.parse(localStorage.getItem("filters")),
    gotFilters: false,
    gotFiltersSeasonChange: false,
    filtersChanged: false,
    broadcasters: JSON.parse(localStorage.getItem("broadcasters")),
    gotBroadcasters: false,

    countriesToExclude: {
        data: null,
        status: 'idle'
    },

    aggregatedCountries: {
        data: JSON.parse(localStorage.getItem('aggregatedCountries')),
        status: 'idle'
    },

    // formValues will also contain a default date range
    formValues: JSON.parse(localStorage.getItem("formValues")) || {
        foreignAreaSelected: false, // Territorio Estero selezionato
        showBroadcaster: true,
        showMap: false,
        updateBtnDisabled: false,
        countrySelected: false
    },

    users: {
        data: {},
        status: 'idle',
        sortFashion: 'ascending'
    },

    groups: {
        data: JSON.parse(localStorage.getItem("userGroups")),
        status: 'idle'
    }
}

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case USER_PROFILE: {
            return {
              ...state,
              profile: action.payload,
            };
          }

        case GOT_PROFILE: {
            return {
                ...state,
                gotProfile: action.payload
            }
        }

        // dispatched to signal that we got the filters (false/true)
        case GOT_FILTERS: {
            return {
                ...state,
                gotFilters: action.payload
            }
        }

        case GOT_FILTERS_SEASON_CHANGE: {
            return {
                ...state,
                gotFiltersSeasonChange: action.payload
            }
        }

        // dispatched when fetching filters for the first time
        case SET_FILTERS: {
            return {
                ...state,
                filters: action.payload
            }
        }

        case FILTERS_CHANGED: {
            return {
                ...state,
                filtersChanged: action.payload
            }
        }

        case UPDATE_FILTER_CURR_VALUES: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.filterName]: {
                        ...state.filters[action.payload.filterName],
                        currValue: action.payload.newCurrValue
                    }
                }
            }
        }

        case DELETE_ROUND_FROM_CURRENT_ROUNDS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    // round filter
                    round: {
                        ...state.filters.round,
                        // array of objects {name: '', label: ''}
                        currValue: state.filters.round.currValue.filter(round => round.name !== action.payload.newCurrValue.name &&
                            round.label !== action.payload.newCurrValue.label)
                        // {
                        //     name: state.filters.round.currValue.name.filter(roundName => roundName !== action.payload.newCurrValue.name),
                        //     label: state.filters.round.currValue.label.filter(roundLabel => roundLabel !== action.payload.newCurrValue.label)
                        // }
                    }
                }
            }
        }


        case ADD_ROUND_TO_CURRENT_ROUNDS: {
            // console.log("Adding round")
            const newState = {
                ...state,
                filters: {
                    ...state.filters,
                    // round filter
                    round: {
                        ...state.filters.round,
                        currValue: [...state.filters.round.currValue, action.payload.newCurrValue]
                    }
                }
            }

            return newState
        }

        case UPDATE_FIRST_CURRENT_ROUND: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    round: {
                        ...state.filters.round,
                        currValue: [action.payload.newCurrValue]
                    }
                }
            }
        }

        case SET_FORM_VALUES: {
            return {
                ...state,
                formValues: action.payload
            }
        }

        case SET_BROADCASTERS: {
            return {
                ...state,
                broadcasters: action.payload
            }
        }

        case GOT_BROADCASTERS: {
            return {
                ...state,
                gotBroadcasters: action.payload
            }
        }

        case SET_EXCLUDED_COUNTRIES: {
            return {
                ...state,
                countriesToExclude: {
                    ...state.countriesToExclude,
                    data: action.payload
                }
            }
        }


        case SET_EXCLUDED_COUNTRIES_STATUS: {
            return {
                ...state,
                countriesToExclude: {
                    ...state.countriesToExclude,
                    status: action.payload
                }
            }
        }

        case SET_AGGREGATED_COUNTRIES: {
            return {
                ...state,
                aggregatedCountries: {
                    ...state.aggregatedCountries,
                    data: action.payload
                }
            }
        }

        case SET_AGGREGATED_COUNTRIES_STATUS: {
            return {
                ...state,
                aggregatedCountries: {
                    ...state.aggregatedCountries,
                    status: action.payload
                }
            }
        }


        case GOT_USERS: {
            return {
                ...state,
                users: {
                    ...state.users,
                    data: action.payload
                }
            }
        }

        case SET_USERS_STATUS: {
            return {
                ...state,
                users: {
                    ...state.users,
                    status: action.payload
                }
            }
        }

        case ADD_USER: {
            const userGroup = action.payload.userGroup;

            return {
                ...state,
                users: {
                    ...state.users,
                    data: {
                        ...state.users.data,
                        [userGroup]: [
                            ...state.users.data[userGroup],
                            action.payload.user
                        ].sort((a, b) => state.users.sortFashion === 'ascending'
                            ? (a.email < b.email) ? -1 : (a.email > b.email) ? 1 : 0
                            : (a.email < b.email) ?  1 : (a.email > b.email) ? -1 : 0
                        )
                    }
                }
            }
        }

        case DELETE_USER: {
            const {id, userGroup} = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    data: {
                        ...state.users.data,
                        [userGroup]: [...state.users.data[userGroup]].filter((u) => u.id !== id)
                    }
                }
            }
        }

        case CHANGE_USER: {
            const {id, userGroup, user} = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    data: {
                        ...state.users.data,
                        [userGroup]: [...state.users.data[userGroup]].map((u) => {
                            if (u.id === id)
                                u = user

                            return u
                        }).sort((a, b) => state.users.sortFashion === 'ascending'
                            ? (a.email < b.email) ? -1 : (a.email > b.email) ? 1 : 0
                            : (a.email < b.email) ?  1 : (a.email > b.email) ? -1 : 0
                        )
                    }
                }
            }
        }

        case SET_USERS_SORT_FASHION: {
            return {
                ...state,
                users: {
                    ...state.users,
                    sortFashion: action.payload
                }
            }
        }

        case GOT_GROUPS: {
            return {
                ...state,
                groups: {
                    ...state.groups,
                    data: action.payload
                }
            }
        }

        case SET_GROUPS_STATUS: {
            return {
                ...state,
                groups: {
                    ...state.groups,
                    status: action.payload
                }
            }
        }

        default:
            return state;
    }
}

export default UserReducer;