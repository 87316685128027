import {
  FETCH_SUCCESS,
  FETCH_ERROR,
  GOT_TEAMS,
  RETRIEVING_TEAMS, CURRENT_TEAM, CURRENT_TEAM_SET, GOT_TEAMS_LIST
} from "../../constants/ActionTypes";

import axios from 'util/Api'
import { initLoginFailed, MAX_RETRIES } from "../../util/utils";
const retry = require('retry');

/**
 * Gets the list of teams.
 */
export const getTeams = () => {
    return (dispatch) => {
      const op = retry.operation({
        retries: MAX_RETRIES,
        factor: 3,
        minTimeout: 1 * 1000,
        maxTimeout: 60 * 1000,
        randomize: true,
      });

      // signal the start of the fetching process.
      // It is useful for knowing when the data is ready.
      // dispatch({type: FETCH_START})
      dispatch({type: RETRIEVING_TEAMS, payload: true});

      op.attempt(async (currAttempt) => {
        // console.log("Teams: attempt number ", currAttempt);
        if (currAttempt <= MAX_RETRIES) {
          axios.get('api/teams/').then((response) => {

            if (response.data) {
              /**
               * Map that associates each Team to its id
               */
              let results = {}
              response.data.map((item) => (
                results[item.id] = item
              ))

              // save teams of the local storage. Redux will always
              // look for the teams list in the local storage.
              localStorage.setItem('teams', JSON.stringify(results));
              localStorage.setItem('teamsList', JSON.stringify(response.data));

              // dispatch an action to save teams in the state
              dispatch({type: GOT_TEAMS, payload: results})
              dispatch({type: GOT_TEAMS_LIST, payload: response.data})

              // end of fetch
              dispatch({type: RETRIEVING_TEAMS, payload: false});
              dispatch({type: FETCH_SUCCESS});
            } else {
              dispatch({type: RETRIEVING_TEAMS, payload: false});
              initLoginFailed(dispatch);
            }
          }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error});
            dispatch({type: RETRIEVING_TEAMS, payload: false});
            console.log("Error while retrieving list of teams \n", error.message);
            if (op.retry(error)) { return; }
          });
        } else {
          initLoginFailed(dispatch);
        }
      })
    }
};


// export const getTeams = () => {
//   return async (dispatch) => {
//     const op = retry.operation({
//       retries: MAX_RETRIES,
//       factor: 3,
//       minTimeout: 1 * 1000,
//       maxTimeout: 60 * 1000,
//       randomize: true,
//     });
//     op.attempt(async (currAttempt) => {
//       console.log("Teams: attempt number ", currAttempt);
//       let response;
//       try {
//         response = await axios.get('api/teams/');

//         if (response && response.data) {
//           /**
//            * Map that associates each Team to its id
//            */
//           let results = {}
//           response.data.map((item) => (
//             results[item.id] = item
//           ))

//           // save teams of the local storage. Redux will always
//           // look for the teams list in the local storage.
//           localStorage.setItem('teams', JSON.stringify(results));
//           localStorage.setItem('teamsList', JSON.stringify(response.data));

//           // dispatch an action to save teams in the state
//           dispatch({type: GOT_TEAMS, payload: results})
//           dispatch({type: GOT_TEAMS_LIST, payload: response.data})

//           // end of fetch
//           dispatch({type: RETRIEVING_TEAMS, payload: false});
//           dispatch({type: FETCH_SUCCESS});
//         } else {
//           dispatch({type: RETRIEVING_TEAMS, payload: false});
//           initLoginFailed(dispatch);
//         }
//       } catch (error) {
//         dispatch({type: FETCH_ERROR, payload: error});
//         dispatch({type: RETRIEVING_TEAMS, payload: false});
//         console.log("Error while retrieving list of teams \n", error.message);
//         if (op.retry(error)) { return; }
//       }
//     })
//   }
// }

/**
 * Sets the current team.
 * @param {Integer} teamId The team id
 */
export const setCurrentTeam = (team) => {
  return (dispatch, getState) => {
    // retrieve the specified team
    // const {teams} = getState().team;
    // const team = teams !== null ? teams[teamId] : null;

    if (team) {
      localStorage.setItem('currentTeam', JSON.stringify(team))
      dispatch({type: CURRENT_TEAM, payload: team})
      dispatch({type: CURRENT_TEAM_SET, payload: true})
    } else {
      console.log("Couldn't update team. Defaulting to first team.")
      // dispatch({type: FETCH_ERROR, payload: "Couldn't update team."})
    }
  }
}