import React from "react";
import {Button, Spin} from "antd";
import "./LoaderButton.css";

export default function LoaderButton({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <Button
      size={"large"}
      className={`LoaderButton ${className}`}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <Spin className="spinning" />}
      {props.children}
    </Button>
  );
}