import {
  INIT_URL, 
  RESET,
  SIGNOUT_USER_SUCCESS, 
  USER_ACCESS_TOKEN_SET,
  USER_REFRESH_TOKEN_SET,
  ACCESS_TOKEN_VALIDITY, 
  VALIDATING_TOKEN,
  EMAIL_SENT,
  IS_SENDING_EMAIL,
  IS_CONFIRMING,
  CONFIRMED,
  RESET_PSW_TOKEN_SET,
  RESET_TOKEN_VALIDITY, 
  RESET_PASSWORD_ERROR, LOGIN_FAILED, RESET_PASSWORD_ERROR_MESSAGE, REFRESH_FAILED} from "../../constants/ActionTypes";

const INIT_STATE = {
  accessToken: JSON.parse(localStorage.getItem('accessToken')), //this is the access token
  refreshToken: JSON.parse(localStorage.getItem('refreshToken')),
  loginFailed: false,
  tokenValidity: false, // access token validity
  validatingToken: false,
  refreshFailed: false,
  initURL: '',
  // profile: JSON.parse(localStorage.getItem('profile')),
  // gotProfile: false,
  reset: {
    isSendingEmail: false,
    emailSent: false,
    token: null, // confirmation token for password reset
    isTokenValid: null,
    confirmed: false,
    isConfirming: false,
    resetPasswordError: false,
    resetPasswordErrorMessage: null
  },

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        profile: null,
        initURL: ''
      }
    }

    case LOGIN_FAILED: {
      return {
        ...state,
        loginFailed: action.payload
      }
    }

    case RESET: {
      return {
        state: INIT_STATE
      }
    }

    case USER_ACCESS_TOKEN_SET: {
      return {
        ...state,
        accessToken: action.payload, // action.payload is the access token
      };
    }

    case USER_REFRESH_TOKEN_SET: {
      return {
        ...state,
        refreshToken: action.payload, // action.payload is the access token
      };
    }

    case ACCESS_TOKEN_VALIDITY: {
      return {
        ...state,
        tokenValidity: action.payload
      }
    }

    case VALIDATING_TOKEN: {
      return {
        ...state,
        validatingToken: action.payload
      }
    }

    case REFRESH_FAILED: {
      return {
        ...state,
        refreshFailed: action.payload
      }
    }

    case EMAIL_SENT: {
      return {
        ...state,
        reset: {
          ...state.reset,
          emailSent: action.payload 
        }
        }
    }

    case IS_SENDING_EMAIL: {
      return {
        ...state,
        reset: {
          ...state.reset,
          isSendingEmail: action.payload
        }
      }
    }

    case IS_CONFIRMING: {
      return {
        ...state,
        reset: {
          ...state.reset,
          isConfirming: action.payload
        }
      }
    }

    case CONFIRMED: {
      return {
        ...state,
        reset: {
          ...state.reset,
          confirmed: action.payload
        }
      }
    }

    case RESET_PASSWORD_ERROR: {
      return {
        ...state,
        reset: {
          ...state.reset,
          resetPasswordError: action.payload
        }
      }
    }    

    case RESET_PASSWORD_ERROR_MESSAGE: {
      return {
        ...state,
        reset: {
          ...state.reset,
          resetPasswordErrorMessage: action.payload
        }
      }
    }

    case RESET_PSW_TOKEN_SET: {
      return {
        ...state,
        reset: {
          ...state.reset,
          token: action.payload
        }
      }
    }

    case RESET_TOKEN_VALIDITY: {
      return {
        ...state,
        reset: {
          ...state.reset,
          isTokenValid: action.payload
        }
      }
    }

    default:
      return state;
  }
}
