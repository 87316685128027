import { 
    GOT_DOCUMENTS, 
    SET_DOCUMENTS_STATUS,
    GOT_FOLDERS,
    SET_FOLDERS_STATUS,
    ADD_DOCUMENT,
    DELETE_DOCUMENT,
    CHANGE_DOCUMENT,
    SET_SORT_FASHION
} from "../../constants/ActionTypes";

const INIT_STATE = {
    // folders with optional subfolders 
    folders: {
        data: JSON.parse(localStorage.getItem('folders')),
        status: 'idle'
    },

    // documents by season
    documents: {
        data: {},
        status: 'idle',
        sortFashion: 'descending'
    }
}

const DocumentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GOT_DOCUMENTS: {
            return {
                ...state,
                documents: {
                    ...state.documents,
                    data: action.payload
                }
            }
        }

        case SET_DOCUMENTS_STATUS: {
            return {
                ...state,
                documents: {
                    ...state.documents,
                    status: action.payload
                }
            }
        }

        case ADD_DOCUMENT: {
            const month = action.payload.month;

            return {
                ...state,
                documents: {
                    ...state.documents,
                    data: {
                        ...state.documents.data,
                        // if this document will be the first in
                        // the specified month's group, let's create
                        // a list with only one document for that month
                        [month]: month in state.documents.data ? [
                            ...state.documents.data[month],
                            action.payload.doc
                        ].sort((a, b) => state.documents.sortFashion === 'ascending'
                            ? new Date(a.date) - new Date(b.date)
                            : new Date(b.date) - new Date(a.date)
                        )
                        : [action.payload.doc]
                    }
                }
            }
        }

        case DELETE_DOCUMENT: {
            const {id, month} = action.payload; 
            return {
                ...state,
                documents: {
                    ...state.documents,
                    data: {
                        ...state.documents.data,
                        [month]: [...state.documents.data[month]].filter((doc) => doc.id !== id)
                    }
                }
            }
        }

        case CHANGE_DOCUMENT: {
            const {id, month, doc} = action.payload;
            return {
                ...state,
                documents: {
                    ...state.documents,
                    data: {
                        ...state.documents.data,
                        [month]: [...state.documents.data[month]].map((d) => {
                            if (d.id === id)
                                d = doc
                            
                            return d
                        }).sort((a, b) => state.documents.sortFashion === 'ascending'
                            ? new Date(a.date) - new Date(b.date)
                            : new Date(b.date) - new Date(a.date)
                        )
                    }
                }
            }
        }

        case SET_SORT_FASHION: {
            return {
                ...state,
                documents: {
                    ...state.documents,
                    sortFashion: action.payload
                }
            }
        }

        case GOT_FOLDERS: {
            return {
                ...state,
                folders: {
                    ...state.folders,
                    data: action.payload
                }
            }
        }

        case SET_FOLDERS_STATUS: {
            return {
                ...state,
                folders: {
                    ...state.folders,
                    status: action.payload
                }
            }
        }

        default:
            return state;
    }
}

export default DocumentReducer;