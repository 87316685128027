import {
  FETCH_ERROR,
  GOT_AUDIENCE_DATA,
  SET_AUDIENCE_DATA,
  AUDIENCE_DATA_FETCH_START,
  AUDIENCE_DATA_FETCH_FINISHED,
  SET_AUDIENCE_PAGE_DATA,
  AUDIENCE_PAGE_DATA_FETCH_START,
  AUDIENCE_PAGE_DATA_FETCH_FINISHED,

  SET_CURR_MATCH_DETAIL,
  CURR_MATCH_DETAIL_FETCH_START,
  CURR_MATCH_DETAIL_FETCH_FINISHED,
  SET_PREV_MATCH_DETAIL,
  PREV_MATCH_DETAIL_FETCH_START,
  PREV_MATCH_DETAIL_FETCH_FINISHED,
  SET_SEASON_AUDIENCE_DATA_STATUS,
  GOT_SEASON_AUDIENCE_DATA,
  SET_CHART_AUDIENCE_DATA_STATUS, API_FETCH_SIGNAL, SET_LAST_PLAYED_ROUND, SET_MOST_RECENT_SEASON
} from "../../constants/ActionTypes";

import {
  getUrlParams,
} from "../../util/utils";

import axios from "../../util/Api"
import lodash from "lodash";

/**
 * Get the audience data of all the matches played in the specified
 * set of matchdays and season, in either Italy or any other world country.
 * Matches audience data is grouped by dates of playing in the
 * specified matchday of a season.
 * @param {Object} filtersCurrValues current values of filters
 * @param {Boolean} grouped a flag indicating if matches audience data should be grouped or not
 */
export const getMatchesAudienceData = (filtersCurrValues, grouped = false, currentTeamId = null) => {
  return (dispatch) => {
    const urlParams = getUrlParams(filtersCurrValues, grouped, currentTeamId)

    // signal the start of fetching process.
    dispatch({ type: AUDIENCE_DATA_FETCH_START, payload: true })
    dispatch({ type: AUDIENCE_DATA_FETCH_FINISHED, payload: false })
    dispatch({ type: API_FETCH_SIGNAL })
    axios.get(`api/matches_audience/?${urlParams}`).then((response) => {
      if (response.data) {
        // const audienceData = listToObject(response.data, 'date', false, true)
        const audienceData = response.data;

        dispatch({ type: GOT_AUDIENCE_DATA, payload: true }) // useless for npw
        dispatch({ type: SET_AUDIENCE_DATA, payload: audienceData })

        // dispatch({type: AUDIENCE_DATA_FETCH_START, payload: false})
        dispatch({ type: AUDIENCE_DATA_FETCH_FINISHED, payload: true })
        dispatch({ type: AUDIENCE_DATA_FETCH_START, payload: false })
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ERROR, payload: error })
      dispatch({ type: AUDIENCE_DATA_FETCH_FINISHED, payload: true })
      dispatch({ type: AUDIENCE_DATA_FETCH_START, payload: false })
    })
  }
}


/**
 * Get the audience data of all the matches played by the team
 * identified by `teamId` in a particular season, competition and
 * set of matchdays, in either Italy or any other world country.
 * Matches audience data is not grouped in any way.
 * @param {Object} filtersCurrValues current values of filters
 * @param {Boolean} grouped a glag indicating if matches' audience should be grouped or not
 * @param {Integer} teamId the id of the team for which we are retrieving the matches
 */
export const getMatchesAudiencePageData = (filtersCurrValues, grouped, teamId) => {
  return (dispatch) => {

    // const urlParams = getUrlParamsAudiencePage(filtersCurrValues, teamId)
    const urlParams = getUrlParams(filtersCurrValues, grouped, teamId)

    dispatch({ type: AUDIENCE_PAGE_DATA_FETCH_START, payload: true })
    dispatch({ type: AUDIENCE_PAGE_DATA_FETCH_FINISHED, payload: false })
    axios.get(`api/matches_audience/?${urlParams}`).then((response) => {
      if (response.data) {
        const audienceData = response.data;

        // set matches' audience data as it is; this will be displayed
        // in the collapsable match list
        dispatch({ type: SET_AUDIENCE_PAGE_DATA, payload: audienceData })

        /*
        // manipulate the audience data in order to be ready to be displayed
        // in the audience page's metric box charts
        dispatch({type: SET_CHART_AUDIENCE_DATA_STATUS, payload: 'loading'})

        const location = filtersCurrValues.country.name === 'IT' ? 'IT' : 'ES';
        const metrics = getMetricsBasedOnLocation(location);

        let chartData = {
          // "metricValues": Object.assign({}, ...metrics.slice(0, -1).map(metric => ( {[`${metric.name}_avg`]: 0, [`${metric.name}_tot`]: 0} ))),
          "metricValues": {
            [metrics[0].name]: {
              "avg": 0, "tot": 0
            },
            [metrics[1].name]: {
              "avg": 0, "tot": 0
            }
          },
          // this object contains lists of <id, value> objects for each match and metric
          "chartObjs": {}
        }
        // console.log("before: ", chartData)
        // console.log("AFTER INIT", Object.assign({}, ...metrics.slice(0, -1).map(metric => ( {[metric.name]: 0} ))))
        Object.keys(chartData.metricValues).forEach((metric) => {
          // console.log(audienceData)
          // compute average for current metric
          chartData.metricValues[metric].avg = lodash.meanBy(audienceData, metric) || 0;
          chartData.metricValues[metric].tot = lodash.sumBy(audienceData, metric) || 0;

          // compute list of objects for all matches and current metric
          // these will be the data points of each metric box chart

          chartData.chartObjs[metric] = lodash(audienceData)
            .map((match) => ({
              id: match.date, value: match[metric], match: match
            }))
            .value()
        })

        dispatch({type: GOT_CHART_AUDIENCE_DATA, payload: chartData})
        dispatch({type: SET_CHART_AUDIENCE_DATA_STATUS, payload: 'complete'})

        */

        dispatch({ type: AUDIENCE_PAGE_DATA_FETCH_FINISHED, payload: true })
        dispatch({ type: AUDIENCE_PAGE_DATA_FETCH_START, payload: false })
      }
    }).catch((error) => {
      console.log(error)
      dispatch({ type: FETCH_ERROR, payload: error })
      dispatch({ type: AUDIENCE_PAGE_DATA_FETCH_FINISHED, payload: true })
      dispatch({ type: AUDIENCE_PAGE_DATA_FETCH_START, payload: false })

      dispatch({ type: SET_CHART_AUDIENCE_DATA_STATUS, payload: 'failed' })
    })
  }
}


/**
 * Retrieves the audience detail for the specified match, country and broadcaster.
 * @param {Integer} matchId the match id
 * @param {String} country the country abbreviation code
 * @param {Integer} broadcaster the broadcaster id
 */
export const getCurrMatchDetail = (matchId, country, broadcaster) => {
  return (dispatch) => {
    const baseUrl = 'api/single_match_audience/?';

    dispatch({ type: CURR_MATCH_DETAIL_FETCH_START, payload: true })
    dispatch({ type: CURR_MATCH_DETAIL_FETCH_FINISHED, payload: false })
    axios.get(baseUrl + `match_id=${matchId}&country=${country}&broadcaster=${broadcaster}`).then((response) => {
      if (response.data) {

        if (!(Object.prototype.toString.call(response.data) === '[object Array]' &&
          response.data.length === 0)) {
          dispatch({ type: SET_CURR_MATCH_DETAIL, payload: response.data })
        } else {
          // is it mandatory to re set it to null in order to avoid caching issues
          dispatch({ type: SET_CURR_MATCH_DETAIL, payload: null })
        }
        dispatch({ type: CURR_MATCH_DETAIL_FETCH_START, payload: false })
        dispatch({ type: CURR_MATCH_DETAIL_FETCH_FINISHED, payload: true })
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ERROR, payload: error })
      dispatch({ type: CURR_MATCH_DETAIL_FETCH_START, payload: false })
      dispatch({ type: CURR_MATCH_DETAIL_FETCH_FINISHED, payload: true })
    })
  }
}

/**
 * Retrieves the audience detail for the specified match, country and broadcaster.
 * @param {Integer} matchId the match id
 * @param {String} country the country abbreviation code
 * @param {Integer} broadcaster the broadcaster id
 */
export const getPrevMatchDetail = (matchId, country, broadcaster) => {
  return (dispatch) => {
    const baseUrl = 'api/single_match_audience/?';

    dispatch({ type: PREV_MATCH_DETAIL_FETCH_START, payload: true })
    dispatch({ type: PREV_MATCH_DETAIL_FETCH_FINISHED, payload: false })
    axios.get(baseUrl + `match_id=${matchId}&country=${country}&broadcaster=${broadcaster}&prev=1`).then((response) => {
      if (response.data) {

        // if prev match hasn't been found, backend returns empty array []
        if (!(Object.prototype.toString.call(response.data) === '[object Array]' &&
          response.data.length === 0)) {
          dispatch({ type: SET_PREV_MATCH_DETAIL, payload: response.data })
        } else {
          // is it mandatory to re set it to null in order to avoid caching issues
          dispatch({ type: SET_PREV_MATCH_DETAIL, payload: null })
        }

        dispatch({ type: PREV_MATCH_DETAIL_FETCH_START, payload: false })
        dispatch({ type: PREV_MATCH_DETAIL_FETCH_FINISHED, payload: true })
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ERROR, payload: error })
      dispatch({ type: PREV_MATCH_DETAIL_FETCH_START, payload: false })
      dispatch({ type: PREV_MATCH_DETAIL_FETCH_FINISHED, payload: true })
    })
  }
}


/**
 * Action dispatcher that handles the download of the audience data
 * which is currently selected by the current filters values.
 * @param {Object} filtersCurrValues the current filters values
 * @param {Integer} teamId an optional team id (audience tv page only)
 */
export const downloadAudienceData = (filtersCurrValues, teamId) => {
  return (dispatch) => {
    const urlParams = getUrlParams(filtersCurrValues, false, teamId)

    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}api/matches_audience/?${urlParams}&download=1`,
      method: 'get',
      responseType: 'blob'
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'audience_data_export.csv');
      document.body.appendChild(fileLink);
      fileLink.click();
    }).catch((error) => {
      dispatch({ type: FETCH_ERROR, payload: error })
    })
  }
}


/**
 * Given a competition, a season, a location and country, this action dispatcher retrieves
 * audience data for the whole season and saves it on the redux state.
 * The audience data needs a bit of manipulation.
 * Given a list of all the matches of the specified season, an object
 * containing a list of <id, value> objects for each match in the season is created.
 * Furthermore, the sum for the specified metric
 * (default: amr) for each of these groups: 1st Round, 2nd Round
 * and whole season is computed.
 * @param {Object} filtersValues values of filters needed to fetch whole season's audience data
 * @param {Boolean} sum true if season audience data should be summed by matchday (home page); false otherwise (audience page)
 * @param {Integer} currentTeam the id of the currently selected team (optional; audience page only)
 */
export const getSeasonAudienceData = (filtersValues, sum, currTeamId) => {
  return (dispatch) => {
    // prepare URL parameters omitting team Id (we don't need it)
    // we only need: competition, location, season, country, broadcaster

    let UrlParams;

    // home page
    if (sum)
      UrlParams = getUrlParams(filtersValues, false)
    else
      UrlParams = getUrlParams(filtersValues, false, currTeamId)

    // fetch started
    dispatch({ type: SET_SEASON_AUDIENCE_DATA_STATUS, payload: 'loading' })

    // make GET request
    axios.get(`api/matches_audience/?${UrlParams}`).then((response) => {
      // if we have at least one match's audience
      if (response.data.length > 0) {

        const competition = filtersValues.competition.name;

        // base object for whole season's audience data
        let seasonAudienceData = competition === 'Serie A' ? {
          // "metric": filtersValues.location.name === 'IT' || filtersValues.country.name === 'IT' ? "amr" : 'live',
          "metric": 'amr',
          "totals": { "1R": 0, "2R": 0, "WS": 0 },
          "chartObjs": [] // this should be an array of 38 elements, one for each matchday in Serie A
        } : {
          "metric": 'amr',
          "totals": {"WS": 0},
          "chartObjs": []
        }

        if (sum) {
          // computing the aggregated sum of the specified metric
          // for each match in each season's matchday
          const seasonAudience = lodash(response.data)
            .groupBy(competition === 'Serie A' ? 'competition_round' : 'day')
            // .map((matchdayMatches, matchday) => ({
            //   id: matchday,
            //   // id: parseInt(matchday.split(' ')[1]),
            //   // Sum over amr for italian data, otherwise sum over live+delayed+repeat for international data
            //   // value: lodash.sumBy(matchdayMatches, seasonAudienceData.metric)
            //   value: filtersValues.location.name === 'IT' || (filtersValues.location.name === 'ES' && filtersValues.country.name === 'IT')
            //     ? lodash.sumBy(matchdayMatches, 'amr')
            //     : lodash.sumBy(matchdayMatches, 'live') + lodash.sumBy(matchdayMatches, 'delayed') + lodash.sumBy(matchdayMatches, 'repeat')
            // }))
            .map((matchdayMatches, matchday) => {
              return {
                id: competition === 'Serie A' ? matchday : matchdayMatches[0]['competition_round'],
                // id: parseInt(matchday.split(' ')[1]),
                // Sum over amr for italian data, otherwise sum over live+delayed+repeat for international data
                // value: lodash.sumBy(matchdayMatches, seasonAudienceData.metric)
                value: filtersValues.location.name === 'IT' || (filtersValues.location.name === 'ES' && filtersValues.country.name === 'IT')
                  ? lodash.sumBy(matchdayMatches, 'amr')
                  : lodash.sumBy(matchdayMatches, 'live') + lodash.sumBy(matchdayMatches, 'delayed') + lodash.sumBy(matchdayMatches, 'repeat')
                    + lodash.sumBy(matchdayMatches, 'highlights') + lodash.sumBy(matchdayMatches, 'aggregated')
            }})
            .sortBy((matchAudience) => competition === 'Serie A' ? parseInt(matchAudience.id.split(' ')[1]) : parseInt(matchAudience.id))
            // .sortBy((matchAudience) => matchAudience.id)
            .value()

          // compute sum of metric
          seasonAudience.forEach((matchAudience) => {
            if (competition === 'Serie A') {
              const currMatchday = parseInt(matchAudience.id.split(' ')[1]);
              // const currMatchday = matchAudience.id;

              // Season's 2nd round
              if (currMatchday >= 38 / 2) {
                seasonAudienceData.totals['2R'] += matchAudience.value
              } else { // Season's 1st round
                seasonAudienceData.totals['1R'] += matchAudience.value
              }
            } else if (competition === 'Coppa Italia') {
              seasonAudienceData.totals['WS'] += matchAudience.value
            }
          })

          if (competition === 'Serie A')
            seasonAudienceData.totals['WS'] = seasonAudienceData.totals['1R'] + seasonAudienceData.totals['2R'];

          seasonAudienceData.chartObjs = seasonAudience;
        } else {
          // here we have whole season's audience data for the specified team
          response.data.forEach((match) => {
            // id of current match
            const matchId = competition === 'Serie A' ? match.competition_round : match.day;

            // list of match objects needed by MetricBox chart
            const obj = {
              id: matchId,
              value: filtersValues.location.name === 'IT' || (filtersValues.location.name === 'ES' && filtersValues.country.name === 'IT')
                ? match[seasonAudienceData.metric]
                : match['live'] + match['delayed'] + match['repeat'] + match['highlights'],
              match: match
            }
            seasonAudienceData.chartObjs.push(obj);

            // compute totals
            const leg = competition === 'Serie A'
              ? parseInt(matchId.split(' ')[1]) >= 38 / 2 ? '2R' : '1R'
              : 'WS';
            seasonAudienceData.totals[leg] += obj.value;
          })

          // whole season total
          if (competition === 'Serie A')
            seasonAudienceData.totals['WS'] = seasonAudienceData.totals['1R'] + seasonAudienceData.totals['2R'];

          // sort by match id
          seasonAudienceData.chartObjs = lodash(seasonAudienceData.chartObjs)
            .sortBy((matchAudience) => competition === 'Serie A' ? parseInt(matchAudience.id.split(' ')[1]) : matchAudience.id)
            .value()
        }

        dispatch({ type: GOT_SEASON_AUDIENCE_DATA, payload: seasonAudienceData })
        dispatch({ type: SET_SEASON_AUDIENCE_DATA_STATUS, payload: 'complete' })
      }
    }).catch((error) => {
      dispatch({ type: FETCH_ERROR, payload: error })
      dispatch({ type: SET_SEASON_AUDIENCE_DATA_STATUS, payload: 'failed' })
    })

  }
}

/**
 * Retrieves the most recently played round given a season and competition.
 * @param {String} season the season
 * @param {String} competition the competition name
 */
export const getLastRound = (season, competition) => {
  return async (dispatch) => {
    const urlParams = `season=${season}&competition=${competition}`;
    let response;
    let lastRound = null;

    try {
      response = await axios.get(`api/get_last_round/?${urlParams}`);

    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error })
      console.log(error)
      return null;
    }

    if (response.data) {
      lastRound = { name: response.data, label: response.data.split(' ')[1] }
      dispatch({ type: SET_LAST_PLAYED_ROUND, payload: lastRound })
      localStorage.setItem("lastRound", JSON.stringify(lastRound));
    }

    return lastRound
  }
}

/**
 * Retrieves the most recent season for which we have data.
 */
export const getMostRecentSeason = () => {
  return async (dispatch) => {
    let response;
    let mostRecentSeason = null;

    try {
      response = await axios.get('api/get_most_recent_season/');

    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error })
      console.log(error)
      return null;
    }

    if (response.data) {
      mostRecentSeason = response.data;
      dispatch({ type: SET_MOST_RECENT_SEASON, payload: mostRecentSeason })
      localStorage.setItem("mostRecentSeason", JSON.stringify(mostRecentSeason));
    }

    return mostRecentSeason
  }
}