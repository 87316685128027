import React from "react";
import {Row, Col} from "antd";
import {Auxiliary} from "../Auxiliary";
import CircularProgress from "../CircularProgress/index";

export const Loader = () => (
    <Auxiliary>
        <Row justify="center" align="middle">
            <Col span={24}>
                <CircularProgress className="gx-loader-400"/>
            </Col>
        </Row>
    </Auxiliary>
)