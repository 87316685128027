import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {Input, Form, message} from "antd";
import LoaderButton from "../components/LoaderButton/LoaderButton";
import {useLocation} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import {useFormFields} from "../util/customHooks";

import {
    CONFIRMED,
    RESET_PASSWORD_ERROR,
    SIGNOUT_USER_SUCCESS} from "../constants/ActionTypes";

import {
    sendResetPasswordEmail,
    setResetTokenValidity,
    changePassword
} from "../appRedux/actions/Auth";

const qs = require('querystring');

const FormItem = Form.Item;

const ResetPassword = (props) => {
    const {getFieldDecorator} = props.form;
    const dispatch = useDispatch();
    const location = useLocation();

    // custom hook to keep track of a Form's state
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        confirmPassword: ""
    })

    // Selecting several state variables from the redux state's auth object
    const {emailSent,
        isSendingEmail,
        isTokenValid,
        isConfirming,
        confirmed,
        resetPasswordError,
        resetPasswordErrorMessage} = useSelector(({auth}) => auth.reset);

    // initialize a state variable with the token passed as a URL's query parameter
    const [{token},] = useState(qs.parse(location.search.slice(1)))

    // Basic Form validation
    const validateEmailForm = () => fields.email.length > 0;
    const validatePasswordForm = () => {
        return fields.password.length >= 8 &&
            fields.password === fields.confirmPassword
    }

    //** HANDLER METHODS */

    /**
     * Handles the click on the send email form.
     * After the form validation, is dispatches an action in order
     * to send the reset password email to the user.
     * @param {Object} e The event object
     */
    const handleSendEmailClick = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if(!err) {
                dispatch(sendResetPasswordEmail(values))
            }
        });
    }

    /**
     * Handles the click on the Change Password form.
     * After the form validation, it dispatches an action to actually
     * change the user's password; to do that a valid password reset token
     * the user's password must be sent along with the request.
     * @param {Object} e The event object
     */
    const handleNewPasswordSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if(!err) {
                dispatch(changePassword(token, values.password))
            }
        })
    }

    //** USE EFFECTS */

    /**
     * This effect is resolved at every render because it doesn't
     * have a dependency array. If resetPasswordError is true
     * it means that an error occurred while resetting the user's password.
     * In this case we dispatch an action to set the resetPasswordError to false
     * in order to avoid infinite loops and we redirect the user to the
     * login page.
     */
    useEffect(() => {
        if (resetPasswordError){
            message.error(resetPasswordErrorMessage
                ? 'Si è verificato un errore durante il reset della password. ' + resetPasswordErrorMessage
                : 'Si è verificato un errore durante il reset della password.'
            , 10);
            dispatch({type: RESET_PASSWORD_ERROR, payload: false})
        }
    }, [resetPasswordError, dispatch, resetPasswordErrorMessage]);

    /**
     * This effect is resolved only once (it's like componentDidMount).
     * We need it to execute only once because we need to
     * check for the presence of the token in the URL and check for
     * its validity only once (i.e when the user clicks on the email's link.)
     */
    useEffect(() => {
        const {token} = qs.parse(location.search.slice(1));
        if (token && confirmed === false) {
            // validate the token
            dispatch(setResetTokenValidity(token))
        }
    }, [dispatch, confirmed, location])

    /**
     * This effect is resolved at mounting time (even though
     * this is a functional component) and each time isTokenValid value
     * changes. If the previous useEffect dispatches an action
     * to set the token's validity to false (i.e the provided token
     * is not valid), the user will be redirected to the login page
     * because the password can't be reset without a valid token.
     * It's important that this useEffect comes after the previous one.
     */
    useEffect(() => {
        // if we found a token in the URL but is not valid
        if (token && isTokenValid === false) {
            message.error("Token non valido. Verrai reindirizzato alla pagina di log-in.")
            setTimeout(() => {
                props.history.push('/signin')
            }, 3000)
        }
    }, [isTokenValid, token, props])


    /**
     * This effect is resolved at mounting time and each time
     * confirmed value changes. When the user prompts the
     * new password in the form and presses on the submit button,
     * an action dispatcher to reset the password is called.
     * If all goes ok, that action dispatcher dispatches an action
     * to set confirmed = true and this useEffect kicks in, resulting
     * in the user being redirected to the login page.
     */
    useEffect(() => {
        if (confirmed) {
            message.success("Password reimpostata con successo! Verrai reindirizzato alla pagina di log-in.")
            dispatch({type: CONFIRMED, payload: false})
            localStorage.clear();
            setTimeout(() => {
                props.history.push('/signin')
                dispatch({type: SIGNOUT_USER_SUCCESS});
            }, 3000)
        }
    }, [confirmed, dispatch, props])

    // render methods
    const renderRequestPasswordReset = () => (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-content-bg" />
                        <div className="gx-app-logo-wid">
                        <h1><IntlMessages id="app.userAuth.forgotPassword"/></h1>
                        </div>
                    </div>
                    <div className="gx-app-login-content">
                        <Form onSubmit={handleSendEmailClick} className="gx-signin-form gx-form-row0">
                            <FormItem>
                                {getFieldDecorator('email', {
                                    initialValue: "",
                                    rules: [{
                                        required: true, type: 'email', message: 'The input is not a valid E-mail!',
                                    }],
                                })(
                                <Input type="email" placeholder="E-mail" onChange={(e) => handleFieldChange(e)} />
                                )}
                            </FormItem>

                            <p> Inserisci il tuo indirizzo email per ricevere istruzioni per
                                reimpostare la tua password. </p>
                            <FormItem className="gx-mb-0 gx-mt-5 align-right">
                                <LoaderButton
                                    htmlType="submit"
                                    isLoading={isSendingEmail}
                                    disabled={!validateEmailForm()}
                                >
                                    Invia E-mail
                                </LoaderButton>
                            </FormItem>
                            <FormItem className="clear-floats">
                                <Link to="/signin">
                                    <p className="gx-mt-3">Torna al login</p>
                                </Link>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderNewPasswordForm = () => (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-content-bg">

                        </div>
                        <div className="gx-app-logo-wid">
                        <h1>Inserisci la nuova password.</h1>
                        </div>
                    </div>
                    <div className="gx-app-login-content">
                        {
                            !token
                                ? (
                                    <div>
                                        <p> Controlla la tua casella e-mail per completare il ripristino della password. </p>
                                        <Link to="/signin">
                                            <p className="gx-mt-3">Torna al login</p>
                                        </Link>
                                    </div>
                                )
                                : ( !confirmed ?
                                    <Form onSubmit={handleNewPasswordSubmit} className="new-password gx-signin-form gx-form-row0">
                                        <FormItem
                                            label="Password"
                                            htmlFor="password">
                                            {getFieldDecorator('password', {
                                            initialValue: "",
                                            rules: [{required: true, message: 'Inserisci la nuova password.'}],
                                            })(
                                                <Input type="password" placeholder="Password" onChange={(e) => handleFieldChange(e)} />
                                            )}
                                        </FormItem>
                                        <FormItem
                                            label="Conferma Password"
                                            htmlFor="confirmPassword">
                                            {getFieldDecorator('confirmPassword', {
                                            initialValue: "",
                                            rules: [{required: true, message: 'Conferma la nuova password.'}],
                                            })(
                                                <Input type="password" placeholder="Conferma Password" onChange={(e) => handleFieldChange(e)}/>
                                            )}
                                            </FormItem>
                                        <FormItem className="gx-mb-0 gx-mt-5 align-right">
                                            <LoaderButton
                                                htmlType="submit"
                                                isLoading={isConfirming && !confirmed}
                                                disabled={!validatePasswordForm()}>
                                                Reimposta
                                            </LoaderButton>
                                        </FormItem>
                                        <div className="clear-floats">
                                            <Link to="/signin">
                                                <p className="gx-mt-3">Torna al login</p>
                                            </Link>
                                        </div>
                                    </Form>
                                    : <p>Loading..</p>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        // password reset email hasn't been sent yet and
        // token hasn't been generated yet.
        !emailSent && !token
            ? renderRequestPasswordReset()
            : renderNewPasswordForm()
    )
}

const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default WrappedResetPasswordForm;