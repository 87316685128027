import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_ACCESS_TOKEN_SET,
  USER_REFRESH_TOKEN_SET,
  ACCESS_TOKEN_VALIDITY,
  EMAIL_SENT,
  IS_SENDING_EMAIL,
  SHOW_MESSAGE,
  RESET_PSW_TOKEN_SET,
  RESET_TOKEN_VALIDITY,
  IS_CONFIRMING,
  CONFIRMED,
  RESET_PASSWORD_ERROR,
  LOGIN_FAILED,
  RESET_PASSWORD_ERROR_MESSAGE
} from "../../constants/ActionTypes";

import axios from 'util/Api'

import {
  getTeams
} from "../actions/TeamActions";

import {
  getUserProfile,
  getFilters,
  getBroadcasters,
} from "../actions/UserActions";
import { getFolders } from "./DocumentActions";



export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

const init = () => {
  return (dispatch) => {
    dispatch(getFolders())

    // Get user profile
    dispatch(getUserProfile());

    // Get list of teams
    dispatch(getTeams());

    // Get broadcasters
    dispatch(getBroadcasters());

    // Get filters values
    dispatch(getFilters(undefined, undefined, true));
  }
}

/**
 * This action dispatcher logs the user in. During this process,
 * it saves both refresh and access tokens in the local storage and
 * in the redux's store. It sets the token's validity to true too.
 * @param {String} email The user email
 * @param {String} password The user password
 */
export const userSignIn = (values) => {
  return (dispatch) => {
    // const op = retry.operation({
    //   retries: MAX_RETRIES,
    //   factor: 3,
    //   minTimeout: 1 * 1000,
    //   maxTimeout: 60 * 1000,
    //   randomize: true,
    // });

    dispatch({type: FETCH_START});
    axios.post('api/token/', {
        username: values.email,
        password: values.password,
      },
      {withCredentials: true}
    ).then(({data}) => {
      if (data) {
        // save both access and refresh tokens in the local storage
        localStorage.setItem("accessToken", JSON.stringify(data.access));
        localStorage.setItem("refreshToken", JSON.stringify(data.refresh));
        axios.defaults.headers.common['authorization'] = "Bearer " + data.access;

        // dispatch actions
        dispatch({type: USER_ACCESS_TOKEN_SET, payload: data.access}); // set the access token
        dispatch({type: USER_REFRESH_TOKEN_SET, payload: data.refresh});// set the refresh token
        dispatch({type: ACCESS_TOKEN_VALIDITY, payload: true})

        dispatch(init());

        dispatch({type: FETCH_SUCCESS});
      }
    }).catch(function (error) {
      dispatch({type: LOGIN_FAILED, payload: true})
      dispatch({type: FETCH_ERROR, payload: error});
      console.log("Error****: ", error.message);
    });
  }
};



/**
 * Logs out the user. To do this, we must wipe both the
 * localstorage and the redux state. It also invalidates
 * the access token.
 */
export const userSignOut = () => {
  return (dispatch) => {
    localStorage.clear()
    dispatch({type: SIGNOUT_USER_SUCCESS});
    dispatch({type: ACCESS_TOKEN_VALIDITY, payload: false})
  }
};

//** PASSWORD RESET ACTIONS */

/**
 * Sends an email to the specified email to reset the user's password
 * @param {String} email The user's email
 */
export const sendResetPasswordEmail = ({email}) => {
  return (dispatch) => {
    // the process has been initiated (we need it in the ResetPassword component)
    dispatch({type: IS_SENDING_EMAIL, payload: true});
    axios.post('api/reset_password/', {
      email: email
    }).then((response) => {

      if(response.data) {
        // email has been sent
        dispatch({type: EMAIL_SENT, payload: true});
        dispatch({type: SHOW_MESSAGE, payload: "Request sent, please check your E-mail."})
      } else {
        dispatch({type: IS_SENDING_EMAIL, payload: true});
      }
    }).catch((err) => {
      // the password reset process must be interrupted
      dispatch({type: IS_SENDING_EMAIL, payload: false});
      dispatch({type: RESET_PASSWORD_ERROR, payload: true})
      // dispatch({type: FETCH_ERROR, payload: "An error occurred while resetting your password."})
    })
  }
}

/**
 * Save the password reset token in the redux's state
 * @param {String} token The password reset token
 */
export const setResetPswToken = (token) => {
  return (dispatch) => {
    dispatch({type: RESET_PSW_TOKEN_SET, payload: token})
  }
}

/**
 * Validates the password reset token.
 * @param {String} token The password reset token
 */
export const setResetTokenValidity = (token) => {
  return (dispatch) => {
    axios.post('api/reset_password/validate_token/', {
      token: token
    }).then((response) => {
      if (response.data) {
        dispatch({type: RESET_TOKEN_VALIDITY, payload: true})
      } else {
        dispatch({type: RESET_TOKEN_VALIDITY, payload: false})
      }
    }).catch((error) => {
      dispatch({type: RESET_TOKEN_VALIDITY, payload: false})
    })
  }
}

/**
 * Given a valid password reset token and the user account's password
 * this action dispatcher changes the user's password.
 * @param {String} token The password reset token
 * @param {String} password The user account's password
 */
export const changePassword = (token, password) => {
  return (dispatch) => {
    // the process of confirming the new password has begun
    dispatch({type: IS_CONFIRMING, payload: true})
    axios.post('api/reset_password/confirm/', {
      token: token,
      password: password
    }).then((response) => {

      if (response) {
        // the new password has been confirmed and set
        dispatch({type: CONFIRMED, payload: true})
        dispatch({type: FETCH_SUCCESS, payload: "Password has been changed."})
        // wipe boh the redux state and the local storage. user must log in again
        localStorage.clear()
      } else {
        dispatch({type: IS_CONFIRMING, payload: false})
      }
    }).catch((error) => {
      console.log(error.response)
      if (error.response.data && error.response.data.password) {
        dispatch({type: RESET_PASSWORD_ERROR_MESSAGE, payload: error.response.data.password});
      }
      // in case of error, interrupt the process of chaning password
      dispatch({type: IS_CONFIRMING, payload: false})
      dispatch({type: CONFIRMED, payload: false})
      // in case of error, signal the ResetPassword that an error occurred.
      dispatch({type: RESET_PASSWORD_ERROR, payload: true})
    })
  }
}
