// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

export const FETCH_TOKEN_VALIDITY_START = 'FETCH_TOKEN_VALIDITY_START';
export const FETCH_TOKEN_VALIDITY_SUCCESS = 'FETCH_TOKEN_VALIDITY_SUCCESS';
export const FETCH_TOKEN_VALIDITY_ERROR = 'FETCH_TOKEN_VALIDITY_ERROR';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_PROFILE = 'USER_PROFILE';
export const GOT_PROFILE = 'GOT_PROFILE';
export const USER_ACCESS_TOKEN_SET = 'USER_ACCESS_TOKEN_SET';
export const USER_REFRESH_TOKEN_SET = 'USER_REFRESH_TOKEN_SET';
export const ACCESS_TOKEN_VALIDITY = "TOKEN_VALIDITY";
export const VALIDATING_TOKEN = "VALIDATING_TOKEN";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const API_FETCH_SIGNAL = "API_FETCH_SIGNAL";

// Reset Redux state
export const RESET = "RESET";

// Reset Password Actions
export const EMAIL_SENT = 'EMAIL_SENT';
export const IS_SENDING_EMAIL = 'IS_SENDING_EMAIL';
export const IS_CONFIRMING = 'IS_CONFIRMING';
export const RESET_PSW_TOKEN_SET = "RESET_PSW_TOKEN";
export const RESET_TOKEN_VALIDITY = 'RESET_TOKEN_VALIDITY';
export const CONFIRMED = 'CONFIRMED';
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_ERROR_MESSAGE = "RESET_PASSWORD_ERROR_MESSAGE";
export const REFRESH_FAILED = "REFRESH_FAILED";


// TEAMS
export const GOT_TEAMS = 'GOT_TEAMS';
export const GOT_TEAMS_LIST = 'GOT_TEAMS_LIST';
export const RETRIEVING_TEAMS = 'RETRIEVING_TEAMS';
export const CURRENT_TEAM_SET = "CURRENT_TEAM_SET";
export const CURRENT_TEAM = "CURRENT_TEAM";

// User
export const GOT_FILTERS = "GOT_FILTERS";
export const SET_FILTERS = "SET_FILTERS";
export const GOT_FILTERS_SEASON_CHANGE = "GOT_FILTERS_SEASON_CHANGE";

export const FILTERS_CHANGED = "FILTERS_CHANGED";
export const GOT_BROADCASTERS = "GOT_BROADCASTERS";
export const SET_BROADCASTERS = "SET_BROADCASTERS";
export const UPDATE_FILTER_CURR_VALUES = "UPDATE_FILTER_CURR_VALUE";
export const SET_FORM_VALUES = "FORM_VALUES_SET";

export const ADD_ROUND_TO_CURRENT_ROUNDS = "ADD_ROUND_TO_CURRENT_ROUNDS";
export const DELETE_ROUND_FROM_CURRENT_ROUNDS = "DELETE_ROUND_FROM_CURRENT_ROUNDS";
export const UPDATE_FIRST_CURRENT_ROUND = "UPDATE_FIRST_CURRENT_ROUND";

export const GOT_USERS = "GOT_USERS";
export const SET_USERS_STATUS = "SET_USERS_STATUS";
export const ADD_USER = "SET_USER";
export const DELETE_USER = "DELETE_USER";
export const CHANGE_USER = "CHANGE_USER";
export const SET_USERS_SORT_FASHION = "SET_USERS_SORT_FASHION";

export const GOT_GROUPS = "GOT_GROUPS";
export const SET_GROUPS_STATUS = "SET_GROUPS_STATUS";
// export const SET_NEW_ROUNDS = "SET_NEW_ROUNDS";
export const PROFILE_UPDATED = "PROFILE_UPDATED"

// MOVE THE FOLLOWING ACTIONS
export const SET_EXCLUDED_COUNTRIES = "SET_EXCLUDED_COUNTRIES";
export const SET_EXCLUDED_COUNTRIES_STATUS = "SET_EXCLUDED_COUNTRIES_STATUS";
export const SET_AGGREGATED_COUNTRIES = "SET_AGGREGATED_COUNTRIES";
export const SET_AGGREGATED_COUNTRIES_STATUS = "SET_AGGREGATED_COUNTRIES_STATUS";



// MATCH
export const GOT_AUDIENCE_DATA = "GOT_AUDIENCE_DATA";
export const SET_AUDIENCE_DATA = "SET_AUDIENCE_DATA";
export const AUDIENCE_DATA_FETCH_START = "AUDIENCE_DATA_FETCH_START";
export const AUDIENCE_DATA_FETCH_FINISHED = "AUDIENCE_DATA_FETCH_FINISHED";

export const GOT_SEASON_AUDIENCE_DATA = "GOT_SEASON_AUDIENCE_DATA";
export const SET_SEASON_AUDIENCE_DATA_STATUS = "SET_SEASON_AUDIENCE_DATA_STATUS";

export const GOT_CHART_AUDIENCE_DATA = "GOT_CHART_AUDIENCE_DATA";
export const SET_CHART_AUDIENCE_DATA_STATUS = "SET_CHART_AUDIENCE_DATA_STATUS";

export const SET_MATCHES_BY_MATCHDAY = "SET_MATCHES_BY_MATCHDAY";

export const SET_LAST_PLAYED_ROUND = "SET_LAST_PLAYED_ROUND";
export const SET_MOST_RECENT_SEASON = "SET_MOST_RECENT_SEASON"; 

// export const GOT_LAST_ROUND = "GOT_LAST_ROUND";
// export const SET_LAST_ROUND_STATUS = "SET_LAST_ROUND_STATUS";

// audience page
export const GOT_AUDIENCE_PAGE_DATA = "GOT_AUDIENCE_PAGE_DATA";
export const SET_AUDIENCE_PAGE_DATA = "SET_AUDIENCE_PAGE_DATA";
export const AUDIENCE_PAGE_DATA_FETCH_START = "AUDIENCE_PAGE_DATA_FETCH_START";
export const AUDIENCE_PAGE_DATA_FETCH_FINISHED = "AUDIENCE_PAGE_DATA_FETCH_FINISHED";


export const SET_CURR_MATCH_DETAIL = "SET_CURR_MATCH_DETAIL";
export const CURR_MATCH_DETAIL_FETCH_START = "CURR_MATCH_DETAIL_FETCH_START";
export const CURR_MATCH_DETAIL_FETCH_FINISHED = "CURR_MATCH_DETAIL_FETCH_FINISHED";

export const SET_PREV_MATCH_DETAIL = "SET_PREV_MATCH_DETAIL";
export const PREV_MATCH_DETAIL_FETCH_START = "PREV_MATCH_DETAIL_FETCH_START";
export const PREV_MATCH_DETAIL_FETCH_FINISHED = "PREV_MATCH_DETAIL_FETCH_FINISHED";

// documents and folders
export const GOT_DOCUMENTS = "GOT_DOCUMENTS";
export const SET_DOCUMENTS_STATUS = "SET_DOCUMENTS_STATUS";
export const ADD_DOCUMENT = "SET_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const CHANGE_DOCUMENT = "CHANGE_DOCUMENT";
export const SET_SORT_FASHION = "SET_SORT_FASHION";

export const GOT_FOLDERS = "GOT_FOLDERS";
export const SET_FOLDERS_STATUS = "SET_FOLDERS_STATUS";