import React from "react";
import { message, Select } from "antd";
import moment from "moment";
import { SIGNOUT_USER_SUCCESS } from "../constants/ActionTypes";

const {Option} = Select;
const dateFormat = require('dateformat');
// const retry = require('retry');

// Months abbreviations
var months = {
    'Jan': 'gennaio',
    'Feb': 'febbraio',
    'Mar': 'marzo',
    'Apr': 'aprile',
    'May': 'maggio',
    'Jun': 'giugno',
    'Jul': 'luglio',
    'Aug': 'agosto',
    'Sep': 'settembre',
    'Oct': 'ottobre',
    'Nov': 'novembre',
    'Dec': 'dicembre'
}

const monthsAndNumbers = {
    'gennaio': 1,
    'febbraio': 2,
    'marzo': 3,
    'aprile': 4,
    'maggio': 5,
    'giugno': 6,
    'luglio': 7,
    'agosto': 8,
    'settembre': 9,
    'ottobre': 10,
    'novembre': 11,
    'dicembre': 12
}

const days = {
    1: 'Lunedì',
    2: 'Martedì',
    3: 'Mercoledì',
    4: 'Giovedì',
    5: 'Venerdì',
    6: 'Sabato',
    7: 'Domenica'
}

var fonteDati = {
  'Coppa Italia': {
    '2021/2022': {
      '*': {__html: '<div>Fonte dati: Auditel ripresi dai siti https://www.superguidatv.it/ e https://www.davidemaggio.it/</div>' }
    },
    '2022/2023': {
      110: {__html: '<div>Fonte dati: Auditel ripresi dai siti https://www.superguidatv.it/ e https://www.davidemaggio.it/</div>' }
    }
  },
  'Primavera 1 TIMVISION': {
    '2021/2022': {
      191: {__html: '<div>Fonte dati: Auditel<br><small>Dati riferiti a individui di età maggiore di 14 anni</small></div>'}
    },
    '2022/2023': {
      191: {__html: '<div>Fonte dati: Auditel<br><small>Dati riferiti a individui di età maggiore di 14 anni</small></div>'}
    }
  },
  'Supercoppa Italiana': {
    '2021/2022': {
      '*': {__html: '<div>Fonte dati: Auditel ripresi dai siti https://www.superguidatv.it/ e https://www.davidemaggio.it/</div>' }
    },
    '2022/2023': {
      110: {__html: '<div>Fonte dati: Auditel ripresi dai siti https://www.superguidatv.it/ e https://www.davidemaggio.it/</div>' }
    }
  },
  'Serie A TIM':  {
    '2021/2022': {
      63: {__html: "<div>Fonte dati: Nielsen</div><div><small>I dati di ciascuna gara sono stati calcolati sulla fascia di 110 minuti, a partire dall'ora di inizio prevista, incluso l’intervallo</small></div>" },
      1: {__html: "<div>Fonte dati: Auditel</div><div><small>I dati di ciascuna gara sono stati calcolati sulla fascia di 110 minuti, a partire dall'ora di inizio prevista, incluso l’intervallo</small></div>" },
    },
    '2022/2023': {
      63: {__html: "<div>Fonte dati: Auditel</div><div><small>I dati di ciascuna gara sono stati calcolati sulla fascia di 110 minuti, a partire dall'ora di inizio prevista, incluso l’intervallo</small></div>" },
      1: {__html: "<div>Fonte dati: Auditel</div><div><small>I dati di ciascuna gara sono stati calcolati sulla fascia di 110 minuti, a partire dall'ora di inizio prevista, incluso l’intervallo</small></div>" },
    }
  }
}

export const formatDate = (date) => {
    let d = Date.parse(date);
    d = dateFormat(d, "dd/mm/yyyy");

    return d;
}

export const formatDateTime = (date, time) => {
    let d = Date.parse(date);
    d = dateFormat(d, "d mmm yyyy");
    let month = dateFormat(d, "mmm");
    d = d.replace(month, months[month]);

    if (time !== undefined && time !== null) {
        let t = time.split(':');
        t.splice(2);
        d = d + ' ore ' + t.join('.');
    }

    return d;
}

export const formatDateTimeWithDay = (date, time) => {
    let d = Date.parse(date);
    d = dateFormat(d, "d mmm yyyy");

    const day = dateFormat(d, "N");
    const month = dateFormat(d, "mmm");

    d = d.replace(month, months[month]);

    // dayOfWeek + date
    d = `${days[day]} ${d}`;

    if (time !== undefined && time !== null) {
        let t = time.split(':');
        t.splice(2);
        d = d + ' ore ' + t.join('.');
    }

    return d;
}

export const formatNumber = (num, addPercentage, maxFractionDigits) => {
    if (num !== undefined && num !== null && num !== 0 && !isNaN(num)) {
        let options = {'maximumFractionDigits': maxFractionDigits};
        return addPercentage
            ? num.toLocaleString('it-IT', options) + '%'
            // we don't round share values
            : Math.round(num).toLocaleString('it-IT', options);
    }

    return '-';
}

export const formatRevenue = (num) => {
    let val = formatNumber(num);
    if (val !== '')
        return val + ' €';

    return '-';
}

export const getFormattedPercRatio = (q1, q2) => {
    var result = 0;
    if (q2 !== undefined && q1  !== undefined && q2 > 0)  {
        result = (q1 / q2) * 100;
    }

    return formatNumber(result, false, 2) + ' %';
}

export const getPercRatio = (q1, q2) => {
    var result = 0;
    if (q2 !== undefined && q1  !== undefined && q2 > 0)  {
        result = (q1 / q2) * 100;
    }

    return parseFloat(result.toFixed(2));
}

/**
 * Returns the list's object corresponding to the specified key and value
 * @param {Array} list a list of objects
 * @param {String} key the object's key
 * @param {String} value the object's key value we are looking for
 */
export const findItemByKeyValue = (list, key, value) => {
    const item = list.filter((obj) => obj[key] === value)[0];
    return item !== undefined ? item : null
}

/**
 * Returns an object comprised of name and label corresponding
 * to the default of a filter. All filters have the same default
 * structure.
 * @param {String} name default filter's name
 * @param {*} label default filter's label
 */
const setDefault = (name, label) => {
    return {name: name, label: label}
}

/**
 * Adds a 'currValue' property to each filter with the corresponding
 * value specified in the currValues object.
 * @param {Object} filters the whole filter's object
 */
export const addCurrValues = (filters, lastRound) => {
    const currValues = {
        broadcaster: setDefault(63, 'DAZN'),
        competition: setDefault('Serie A', 'Serie A Enilive'),
        country: setDefault('IT', 'Italia'),
        location: setDefault('IT', 'Domestico'),
        round: [lastRound ? lastRound : setDefault('all', 'Tutta la stagione')],
        season: filters ? filters.season.values[0] : setDefault('2024/2025', '2024/2025') // most recent season
    }

    // iterate over each filter property in currValues and add
    // a currValue property to the filters object
    for (const [filter, value] of Object.entries(currValues)) {
        filters[filter]['currValue'] = value;
    }
}

/**
 * Returns the default value object for the specified filter.
 * @param {String} filterName name of a filter
 */
export const getDefaultFilterValue = (filterName) => {
    const defaults = {
        broadcaster: setDefault(63, 'DAZN'),
        competition: setDefault('Serie A', 'Serie A Enilive'),
        country: setDefault('IT', 'Italia'),
        location: setDefault('IT', 'Domestico'),
        round: [setDefault('Matchday 1', '1')],
        season: setDefault('2024/2025', '2024/2025') // most recent season
    }

    return defaults[filterName]
}

/**
 * Converts a list of objects to an object of objects
 * using the provided key as key.
 * @param {Array} filters a list of objects
 * @param {String} key the object's key to be used as key
 * @param {Boolean} addDefault tells us whether a current value field should be added or not
 * @param {Boolean} deleteKey deletes the key from the object if true
 */
export const listToObject = (filters, key, addCurrValue, deleteKey) => {
    let o = {};

    filters.forEach((filter) => {
        // competition, location, etc..
        const filterName = filter[key]

        if (deleteKey)
            delete filter[key]

        o[filterName] = filter;

        // we need to set the most recently played round as the default for
        // the round filter
        if (addCurrValue) {
            o[filterName]["currValue"] = filterName === "broadcaster"
                ? filter.values['IT'][2] // sky
                : filter.name === "round"
                    ? [filter.values['Serie A'].values[0]] // array of one round
                    : filter.name === "country"
                        ? {name: 'IT', label: 'Italia'}
                        : filter.values[0]
        }

    })

    return o
}

/**
 * Creates an object of current filter's values.
 * Each key is the filter's name
 * E.g
 * {
 *  "competition": {"name": "Serie A", "label": "Serie A TIM"},
 *  etc
 * }
 * @param {Object} filters The filters object
 */
export const getCurrValues = (filters) => {
    let currValues = {};

    if (filters) {
        Object.keys(filters).map((filterName) => (
            currValues[filterName] = filters[filterName].currValue
        ))
    }

    return currValues
}


/**
 * Retrieves the object associated to the specified key
 * is a list of objects.
 * @param {String} key a key value
 * @param {Array} listOfObjects a list of objects
 */
export const findItemByKey = (key, listOfObjects) => {
    const res = listOfObjects.filter((o) => o.name === key)
    return res.length > 0 ? res[0] : null
}

/**
 * Takes an object of filter objects and returns a string of query parameters separated by &
 * If page === 'home' we have season, if audience, we have team id and start/end date
 * @param {Object} filtersValues an object of objects representing the current filers' values
 * @param {Boolean} grouped a flag that indicates whether we want results aggregated by date or not
 * @param {Integer} teamId an optional parameter representing the team id
 */
export const getUrlParams = (filtersValues, grouped, teamId) => {
    let urlParams = "";
    for (const [key, value] of Object.entries(filtersValues)) {
        if (key === 'round') {
            // specify a round URL param for each selected round.
            let cycled = ''
            value.forEach((round) => {
                cycled += `${key}=${round.name}&`
            })
            urlParams = cycled
        } else {
            urlParams += `${key}=${value.name}&`;
        }
    }

    // chech whether team id has been given or not
    if (teamId)
        urlParams += `team=${teamId}&`

    if (grouped)
      urlParams += 'grouped=1'
    else
      urlParams = urlParams.slice(0, -1)

    return urlParams
  }


/**
 * This method tries to load the specified image
 * (e.g a broadcaster or a team) given a folder key
 * that specifies the folder.
 * (e.g folder: teams entityName: Milan)
 * @param {String} folder The folder's name which contains the resource we are looking for
 * @param {String} resourceName The resource name
 */
export const loadImage = (folder, resourceName) => {
    let url;
    try {
        url = require(`../assets/images/${folder}/${resourceName}`)
    } catch (err) {
        url = 'https://via.placeholder.com/150x150'
    }

    return url
  }

/**
 * Returns a list of object metrics depending on the location (either IT or ES)
 * @param {String} location a string representing the location (either IT or ES)
 */
export const getMetricsBasedOnLocation = (location) => {
    return location === 'IT'
        ? [
            {name: 'amr', label: 'AMR'},
            {name: 'rch', label: 'RCH'},
            {name:  'share', label: 'SHARE'}
        ]
        : [
            // {name: 'live', label: 'LIVE AMR'},
            // {name: 'delayed', label: 'DELAYED AMR'},
            // {name: 'repeat', label: 'REPEAT AMR'},
            // {name: 'highlights', label: 'HIGHLIGHTS'},
            {name: 'aggregated', label: 'AGGREGATED'},
        ]
}

/**
 * Returns an arrow icon pointing downwards or upwards
 * depending on the Year over Year value between the two
 * metrics values.
 * @param {Integer | Float} currValue current metric value
 * @param {Integer | Float} prevValue previous metric value
 */
export const arrowIcon = (currValue, prevValue) => {
    if (!currValue || !prevValue) return '-'

    let yoy;
    if (prevValue > 0) {
        yoy = ((currValue - prevValue) / prevValue) * 100;

        if (yoy > 0) {
            return (
                <div className="value-up">
                    <i className="icon icon-menu-up" /> {'+' + formatNumber(yoy, true, 2)}
                </div>
            )
        } else if (yoy < 0) {
            return (
                <div className="value-down">
                    <i className="icon icon-menu-down" /> {formatNumber(yoy, true, 2)}
                </div>
            )
        } else if (yoy === 0) {
            return "0 %"
        }
    }
}

/**
 * Takes either a single date or an array of dates and returns the corresponding
 * moment object(s).
 * @param {String | Array} date either a single date or an array of string dates
 */
export const momentize = (date) => {
    let conv = []; // conversion(s)
    if (Object.prototype.toString.call(date) === '[object Array]') {
        date.map((aDate) => conv.push(moment(aDate)));
    } else {
        conv = moment(date);
    }

    return conv;
}

/**
 * Takes either a single or an array of moment objects and returns the corresponding
 * formatted date
 * @param {String | Array} date either a single date or an array of moment objects
 * @param {String} format the date format (e.g YYYY-MM-DD)
 */
export const demomentize = (date, format) => {
    let conv = []; // conversion(s)
    if (Object.prototype.toString.call(date) === '[object Array]') {
        date.map((aDate) => conv.push(aDate.format(format)));
    } else {
        conv = date.format(format);
    }

    return conv;
}

/**
 * Shows a success message
 */
export const success = (text) => {
    message.success(text);
};

/**
 * Shows an error message
 */
export const error = (text) => {
    message.error(text);
};

// or ES6+ syntax with cool fat arrows
export const containsObject = (list, obj) => {
    return list.some(elem => JSON.stringify(elem) === JSON.stringify(obj))
  }

/**
 * Takes a list of filter's values and creates a list of
 * react's Options
 * @param {Array} values a list of filter's values
 */
export const getOptions = (values, field=undefined) => {
    return (
        values
        ? values.map((item) => (
            <Option
                value={item.name}
                key={item.name}
                label={field ? item[field] : item.label}
                >
                {field ? item[field] : item.label}
            </Option>
        ))
        : []
    )
}

/**
 * Get month's full name in Italian starting with a date.
 * @param {String} date a date.
 */
export const getMonthNameFromDate = (date) => {
    const monthShort = moment().month(moment(date).month()).format('MMM');
    return months[monthShort];
}

/**
 * Convert a base64 array to an ArrayBuffer. Needed for downloading purposes.
 * @param {*} data the base64 data
 */
export const base64ToArrayBuffer = (data) => {
    const binaryString = window.atob(data);
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }

    return bytes;
}

/**
 * Create a link that will trigger the download of the document uplon clicking on it.
 * @param {ArrayBuffer} doc the document to be downloaded
 * @param {String} fileName the name of the file for the download
 */
export const getDownloadLink = (doc, fileName) => {
    const bytes = base64ToArrayBuffer(doc)
    const fileURL = window.URL.createObjectURL(new Blob([bytes]));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);

    return fileLink
}

/**
 *
 * @param {String} key the object's key we are testing against to find the object
 * @param {String|Integer} value the value for the specified key we are looking for
 * @param {Array} list a list of objects
 */
export const findObjInArray = (key, value, list) => {
    return list.find((o) => o[key] === value)
}

/**
 * Return the filename (name + extension) of a file given its
 * cloud storage's URL.
 * @param {String} fileURL a document cloud storage's url
 */
export const getFilenameFromURL = (fileURL) => {
    if (fileURL !== null) {
        let fileName = fileURL.split('?')[0]
        const idx = fileName.lastIndexOf('/');
        return fileName.substr(idx+1)
    }

    return "File caricato"
}

/**
 * Sort the documents object by the specified fashion. If deep is set to true,
 * it sorts the arrays of documents associated to each object's keys.
 * @param {Object} obj The document object to sort
 * @param {String} fashion the sort fashion (i.e ascending | descending)
 * @param {Boolean} deep true to sort the arrays of each key
 * @param {String} objKey the object's key to sort by
 */
export const sortDocuments = (obj, fashion, deep, objKey) => {
    let sorted = {}

    // map each month to its number
    let keys = Object.keys(obj).map((key) => monthsAndNumbers[key]);

    // sort keys by specified fashion
    keys = keys.sort((k1, k2) => fashion === 'ascending' ? k1-k2 : k2-k1);

    // sort each key's array (the value) by specified key
    if (deep) {
        keys.forEach((key) => {
            if (objKey === 'date') {
                const monthName = months[moment(key, 'MM').format('MMM')]
                sorted[monthName] = obj[monthName].sort((a, b) => fashion === 'ascending'
                    ? new Date(a.date) - new Date(b.date)
                    : new Date(b.date) - new Date(a.date)
                )
            }
        })
    }

    return sorted
}

/**
 * Given an object with some key, sort the array of objects
 * by the specified object's key
 * @param {Object} obj The object to sort
 * @param {String} fashion can be either ascending or descending
 * @param {String} objKey the key to use when sorting (e.g name or email)
 */
export const sortObject = (obj, fashion, objKey) => {
    let sortedObj = {}

    Object.keys(obj).map((user_group) => {
        return sortedObj[user_group] = obj[user_group].sort((a, b) => fashion === 'ascending'
            ? (a[objKey] < b[objKey]) ? -1 : (a[objKey] > b[objKey]) ? 1 : 0
            : (a[objKey] < b[objKey]) ?  1 : (a[objKey] > b[objKey]) ? -1 : 0
        )
    })

    return sortedObj
}

/**
 * Verified that the input string represents an URL.
 * @param {String} str
 */
export const isURL = (str) => {
    try {
        new URL(str);
    } catch (_) {
        return false;
    }

    return true;
}

/**
 * Validates the specified email
 * @param {String} email
 */
export const validateEmail = (email) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/* Helper function */
export const downloadFileFromSignedURL = (fileURL, fileName) => {
    // for non-IE
    if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;
	       if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
				document.location = save.href;
// window event not working here
			}else{
		        var evt = new MouseEvent('click', {
		            'view': window,
		            'bubbles': true,
		            'cancelable': false
		        });
		        save.dispatchEvent(evt);
		        (window.URL || window.webkitURL).revokeObjectURL(save.href);
			}
    }

    // for IE < 11
    else if ( !! window.ActiveXObject && document.execCommand)     {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
}

export const MAX_RETRIES = 2;

export const initLoginFailed = (dispatch) => {
    message.error('Errore nel recupero informazioni.\n Si prega di rieffettuare il login.')
    setTimeout(() => {
        // we weren't able to fetch folders, we need to force signout
        dispatch({type: SIGNOUT_USER_SUCCESS});
        localStorage.clear();
    }, 3000)
}

/**
 * Given a competition label return source of the data
 * @param {String} competitionLabel
 */
 export const getFonteDatiPerCompetitionLabelAndSeason = (competitionLabel, season, country, broadcaster) => {
  // console.log({ competitionLabel, season, country, broadcaster })

  let x = {};
  if (Object.hasOwn(fonteDati, competitionLabel)) {
    if (Object.hasOwn( fonteDati[competitionLabel], season)) {
        x = fonteDati[competitionLabel][season]
    }
  }
  if (Object.hasOwn(x, broadcaster)) {
    return x[broadcaster];
  } else if (Object.hasOwn(x, '*')) {
    return x['*'];
  } else {
    return null;
  }
}