import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Button, Checkbox, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import useScript from 'util/useScript';


import {
  userSignIn
} from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";

import {
  error
} from "../util/utils";
import { LOGIN_FAILED } from "../constants/ActionTypes";

const FormItem = Form.Item;

const SignIn = (props) => {

  // hook that allows us to dispatch actions to the redux store
  const dispatch = useDispatch();
  const { tokenValidity, loginFailed } = useSelector(({auth}) => auth);

  const {folders} = useSelector((state) => state.document);
  const {profile, broadcasters, filters} = useSelector((state) => state.user);
  const {teams} = useSelector((state) => state.team);

  /** {auth} means: destructure the redux store and take the auth object
    * i.e the piece of state managed by the auth's reducer.
    * In particular, return the token object.
  */
  const token = useSelector(({auth}) => auth.accessToken);

  const [isLoading, setIsLoading] = useState(false);

  // We want to include the script only in this page
  useScript('https://datalysm-additional-tracking-dot-thirdplace-pixel.appspot.com/static/form_tracking/dist/tracker.js');


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    props.form.validateFields((err, values) => {
      if (!err) {
        // Log in
        dispatch(userSignIn(values));
      } else {
        setIsLoading(false);
      }
    });
  };

  /**
   * This use effect kicks in whenever the user tries to log in
   * with wrong credentials. A LOGIN_FAILED=true action is dispatched
   * in the userSignIn action dispatcher and here we need to reset it to
   * the original false value in order to be able to show the error
   * message every time credentials are wrong, because redux state won't
   * initialize unless the page is refreshed.
   */
  useEffect(() => {
    if (loginFailed) {
      error("Username e/o password errata")

      // reset redux loginFailed flag
      dispatch({type: LOGIN_FAILED, payload: false})
      setIsLoading(false);
    }
  }, [loginFailed, dispatch])

  /**
   * This hook is executed after the first render and whenever either token
   * or props.history (in general any element in the dependency array) changes.
   * When we submit the form, if the data we entered is correct we are given
   * a fresh new pair of access/refresh tokens. The access token is saved in
   * the piece of redux's state managed by the auth reducer: auth.token
   * When this happens, one of the depedency of this hook changes and the effect
   * is fired -> we are brought to the home page.
   */
  useEffect(() => {
    // this means we are authenticated
    if (token !== null && tokenValidity && (
      folders.data && teams && profile && broadcasters && filters
    )) {
      props.history.push('/');
    }
  }, [token, tokenValidity, props.history, folders, profile,
    broadcasters, filters, teams]);

  const {getFieldDecorator} = props.form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-title">Benvenuto nel portale Club Lega Serie A</div>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
          </div>
          <div className="gx-app-login-content">
            <Form onSubmit={(e) => handleSubmit(e)} className="gx-signin-form gx-form-row0">

              <FormItem>
                {getFieldDecorator('email', {
                  initialValue: "",
                  rules: [{
                    required: true, message: 'Il campo username non può essere vuoto',
                  }],
                })(
                  <Input name="email" placeholder="E-mail o Username"/>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  initialValue: "",
                  rules: [{required: true, message: 'Inserisci la password'}],
                })(
                  <Input type="password" placeholder="Password"/>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(
                  <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                )}
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition"/></span>
              </FormItem>
              <FormItem className="align-right">
                <Button type="primary" className="gx-mb-0" htmlType="submit" loading={isLoading}>
                  Accedi
                </Button>
              </FormItem>
              <FormItem className="clear-floats">
                <Link to="/reset">
                  <p className="gx-mt-3">Hai dimenticato la password?</p>
                </Link>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
