import {
    GOT_AUDIENCE_DATA,
    SET_AUDIENCE_DATA,
    AUDIENCE_DATA_FETCH_START,
    AUDIENCE_DATA_FETCH_FINISHED,

    GOT_AUDIENCE_PAGE_DATA,
    SET_AUDIENCE_PAGE_DATA,
    AUDIENCE_PAGE_DATA_FETCH_START,
    AUDIENCE_PAGE_DATA_FETCH_FINISHED,

    SET_CURR_MATCH_DETAIL,
    CURR_MATCH_DETAIL_FETCH_START,
    CURR_MATCH_DETAIL_FETCH_FINISHED,
    SET_PREV_MATCH_DETAIL,
    PREV_MATCH_DETAIL_FETCH_START,
    PREV_MATCH_DETAIL_FETCH_FINISHED,

    GOT_SEASON_AUDIENCE_DATA,
    SET_SEASON_AUDIENCE_DATA_STATUS,
    GOT_CHART_AUDIENCE_DATA,
    SET_CHART_AUDIENCE_DATA_STATUS,
    SET_MATCHES_BY_MATCHDAY,
    SET_LAST_PLAYED_ROUND, SET_MOST_RECENT_SEASON
} from "../../constants/ActionTypes";

const INIT_STATE = {
    audienceData: [],
    gotAudienceData: false, // useless for now
    audienceDataFetchStart: false,
    audienceDataFetchFinished: false,

    audiencePageData: [],
    gotAudiencePageData: false, // useless for now
    audiencePageDataFetchStart: false,
    audiencePageDataFetchFinished: false,

    currMatchDetail: null,
    currMatchDetailFetchStart: false,
    currMatchDetailFetchFinished: false,

    prevMatchDetail: null,
    prevMatchDetailFetchStart: false,
    prevMatchDetailFetchFinished: false,

    seasonAudience: {
        data: {},
        status: 'idle',
        error: null
    },

    // find a better name to indicate the object
    // containing audience data for the audience page's
    // metric charts
    chartAudience: {
        data: {},
        status: 'idle',
        error: null
    },

    matchesByMatchday: {},
    // lastRound: {
    //     value: null,
    //     status: 'idle'
    // }

    lastPlayedRound: JSON.parse(localStorage.getItem("lastRound")),
    // set only once
    mostRecentSeason: JSON.parse(localStorage.getItem("mostRecentSeason"))
}


const MatchReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        // AUDIENCE DATA
        // useless for now
        case GOT_AUDIENCE_DATA: {
            return {
                ...state,
                gotAudienceData: action.payload
            }
        }

        case SET_AUDIENCE_DATA: {
            return {
                ...state,
                audienceData: action.payload
            }
        }

        case AUDIENCE_DATA_FETCH_START: {
            return {
                ...state,
                audienceDataFetchStart: action.payload
            }
        }

        case AUDIENCE_DATA_FETCH_FINISHED: {
            return {
                ...state,
                audienceDataFetchFinished: action.payload
            }
        }


        // AUDIENCE PAGE

        case GOT_AUDIENCE_PAGE_DATA: {
            return {
                ...state,
                gotAudiencePageData: action.payload
            }
        }

        case SET_AUDIENCE_PAGE_DATA: {
            return {
                ...state,
                audiencePageData: action.payload
            }
        }

        case AUDIENCE_PAGE_DATA_FETCH_START: {
            return {
                ...state,
                audiencePageDataFetchStart: action.payload
            }
        }

        case AUDIENCE_PAGE_DATA_FETCH_FINISHED: {
            return {
                ...state,
                audiencePageDataFetchFinished: action.payload
            }
        }

        // CURRENT MATCH DETAIL
        case SET_CURR_MATCH_DETAIL: {
            return {
                ...state,
                currMatchDetail: action.payload
            }
        }

        case CURR_MATCH_DETAIL_FETCH_START: {
            return {
                ...state,
                currMatchDetailFetchStart: action.payload
            }
        }

        case CURR_MATCH_DETAIL_FETCH_FINISHED: {
            return {
                ...state,
                currMatchDetailFetchFinished: action.payload
            }
        }

        // PREVIOUS MATCH DETAIL
        case SET_PREV_MATCH_DETAIL: {
            return {
                ...state,
                prevMatchDetail: action.payload
            }
        }

        case PREV_MATCH_DETAIL_FETCH_START: {
            return {
                ...state,
                prevMatchDetailFetchStart: action.payload
            }
        }

        case PREV_MATCH_DETAIL_FETCH_FINISHED: {
            return {
                ...state,
                prevMatchDetailFetchFinished: action.payload
            }
        }

        // Whole season's data
        case GOT_SEASON_AUDIENCE_DATA: {
            return {
                ...state,
                seasonAudience: {
                    ...state.seasonAudience,
                    data: action.payload
                }
            }
        }

        case SET_SEASON_AUDIENCE_DATA_STATUS: {
            return {
                ...state,
                seasonAudience: {
                    ...state.seasonAudience,
                    status: action.payload
                }
            }
        }

        // case SEASON_AUDIENCE_DATA_FETCH_FINISHED: {
        //     return {
        //         ...state,
        //         seasonAudienceDataFetchFinished: action.payload
        //     }
        // }

        case GOT_CHART_AUDIENCE_DATA: {
            // console.log("payload: ", action.payload)
            return {
                ...state,
                chartAudience: {
                    ...state.chartAudience,
                    data: action.payload
                }
            }
        }

        case SET_CHART_AUDIENCE_DATA_STATUS: {
            return {
                ...state,
                chartAudience: {
                    ...state.chartAudience,
                    status: action.payload
                }
            }
        }

        // case GOT_LAST_ROUND: {
        //     return {
        //         ...state,
        //         lastRound: {
        //             ...state.lastRound,
        //             value: action.payload
        //         }
        //     }
        // }

        // case SET_LAST_ROUND_STATUS: {
        //     return {
        //         ...state,
        //         lastRound: {
        //             ...state.lastRound,
        //             status: action.payload
        //         }
        //     }
        // }

        case SET_MATCHES_BY_MATCHDAY: {
            return {
                ...state,
                matchesByMatchday: action.payload
            }
        }

        case SET_LAST_PLAYED_ROUND: {
            return {
                ...state,
                lastPlayedRound: action.payload
            }
        }

        case SET_MOST_RECENT_SEASON: {
            return {
                ...state,
                mostRecentSeason: action.payload
            }
        }

        default:
            return state
    }
}

export default MatchReducer;